/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of subscription software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */

/**
  * Common Plugin
  *
  * @author Mohamed yasar arabath M
  */

import React from 'react';
import Framework from '@greenville/framework';
import { getSnapshot } from 'mobx-state-tree';
import {
  AUTH_HOME,
  BUNDLED_PLATFORM,
  COURSE_TYPE_SMS,
  COURSE_TYPE_REGISTRAR,
  CHAPTER_ID_QUERY_PARAM,
  EREADER,
  EREADER_CATEGORY,
  EVENT_EREADEREVENT,
  EREADER_ACTION_ACCESS_ETEXT,
  PRODUCT_MODEL,
  SOURCE_OF_EREADER_LAUNCH,
  LMS,
  REDIRECTURL,
  BRAZE_IDENTIFY_USER,
  CHANNEL_SECTION_FILTERS,
  BRAZE_EREADER_COMPONENTS,
  DRAWER_STATE_OPEN,
  DRAWER_PANEL_AUDIO,
  DRAWER_PANEL_CHANNELS,
  DRAWER_PANEL_STUDY,
  DRAWER_PANEL_NOTEBOOK,
  AITutorLoaded,
  GO_TO_PAGENO_QUERY_PARAM,
  SOURCE_FROM,
  CUSTOM_COLLECTIONS,
  QR_CODE_SERVICE_FETCHED,
  NOT_AVAILABLE
} from '../constants';
import CommonUtils from '../utils/CommonUtils';
import PathUtils from '../utils/PathUtils';
import env from '../env';
import BrazeUtils from '../utils/BrazeUtils';
import PendoUtils from '../utils/PendoUtils';
import HeroBannerPlugin from '../../modules/authhome/plugin/HeroBannerPlugin';
import PrintMessage from '../components/printmessage/PrintMessage';

export default class CommonPlugin {
  constructor(context, location = AUTH_HOME) {
    this.commonContext = context;
    this.location = location;
    this.redirectUrl = PathUtils.getQueryParameterbyName(REDIRECTURL);
    this.isFromDeepLink = CommonUtils.isQueryParamAvailable(LMS, 'y') || CommonUtils.isIntergratedMLMLaunch(); // ereader launch from deeplink
    this.initializeMixPanelEventListener();
    this.init();
  }

  init = () => {
    Framework.getEventManager().on(QR_CODE_SERVICE_FETCHED, (response) => {
      this.updateQRCode(response);
    });
  }

  /**
   * subscribe for api data
   * @param {*} response
   */

  updateQRCode = (response) => {
    const {
      jwtToken, uId, isError = false, closeConnection = false
    } = response;
    let url = '';
    if (jwtToken && uId) {
      const constructedQueryParams = new URLSearchParams(this.commonContext.nativeAppBannerPlugin.constructQRCodeQueryParam(jwtToken, uId));
      url = `${env.APPSFLYER_BASE_URL}?${constructedQueryParams.toString()}`;
    }

    if (isError || closeConnection) {
      if (CommonUtils.controller) {
        CommonUtils.controller.abort();
      }
    }

    if (closeConnection) {
      this.commonContext.setState(state => ({
        modalProps: {
          ...state.modalProps,
          open: false
        }
      }));

      return;
    }

    this.commonContext.setState(state => ({
      modalProps: {
        ...state.modalProps,
        ...CommonUtils.getModalProps(),
        qrCode: url,
        open: true,
        isQRCodeMsg: isError ? this.commonContext.props.language.getMessage('header.qrCode.errorMsg') : ''
      }
    }));
  }

  /**
   * Initialize MixPanel Event Listener
   * This function create getMixPanelAdditionalParams global function for mixpanel event
   * commuincation bridge
   *
   */

  initializeMixPanelEventListener = () => {
    const self = this;
    window.getMixPanelAdditionalParams = () => {
      let baseFieldsData = {};
      try {
        const { course } = self.commonContext.props;
        const product = self.getProduct();
        const courseIdType = product.platform === BUNDLED_PLATFORM ? COURSE_TYPE_SMS : COURSE_TYPE_REGISTRAR;
        if (self.location === EREADER) {
          baseFieldsData = {
            product_id: product.id || null,
            product_platform_code: product.platform || null,
            product_model_name: product.model || PRODUCT_MODEL,
            business_model_code: product?.subscription?.businessModelCode || null,
            book_id: product.bookID || product.id || null,
            pearson_subscription_id: product?.subscription?.subscriptionId || null,
            pearson_subscription_tier_code: product?.subscription?.entitlementLevel || null,
            pearson_subscription_tier: product?.subscription?.entitlementTier || null,
            course_id: course ? course.id : null,
            course_id_type: course ? courseIdType : null,
            course_section_id: course ? course.id : null,
            course_section_id_type: course ? courseIdType : null,
            course_section_title: product.title || null,
            channel_id: product.channel_id || null,
            entitlement_id: product?.subscription?.entitlementLevel || null
          };
        }
      } catch (e) {
        console.log('Mixpanel base fields update error', e);
      }

      return baseFieldsData;
    };
  }

  /**
   * Set or update current lang to html element lang attribute for a11y
   * @param {*} lang
   */
  updateLangAttribute(lang = null) {
    const { language } = this.commonContext.props;
    if (language && document.documentElement) {
      const currentLangage = document.documentElement.lang;
      const updateLanguage = lang || language.currentLanguage;
      if (currentLangage !== updateLanguage) {
        document.documentElement.lang = updateLanguage;
      }
    }
  }

  /**
   * Method to handle Integrated MLMLaunch page navgaivation
   * @param {*} pageId
   */

  handleIntegratedMLMLaunchMessage = (pageId) => {
    const { asset } = this.commonContext.props;
    if (pageId) {
      const chapterId = asset.getChapterId(pageId);
      const navData = chapterId ? { chapterId, action: null } : { };
      this.commonContext.handleNavigationChange(pageId, null, navData);
    }
  }

  /**
   * Sets whether it is a PPlus product and sets the appropriate
   * gps subscription of the product
   *
   */
  setGpsSubscriptionOfProduct = () => {
    const { product, course } = this.commonContext.props;
    if (product || course) {
      const actualProduct = product || course.getProduct();
      actualProduct.setIsPPlusProductAndGpsSubscriptionOfProduct();
    }
  }

  /**
   * Returns true if the product is PPlus
   *
   * @returns
   */
  isPPlusProduct = () => {
    const { product, course } = this.commonContext.props;

    return product
      ? product.getIsPPlusProduct()
      : course.getProduct().getIsPPlusProduct();
  }

  /**
   * To get product details
   *
   * @returns
   */
  getProduct = () => {
    const { product, course } = this.commonContext.props;
    let actualProduct = {};

    if (product || course) {
      actualProduct = product || course.getProduct();
    }

    return actualProduct;
  }

  /**
   * Returns true if the channel name exists for book
   *
   * @returns
   */
  hasChannelName = () => {
    let hasChannelName = false;
    const actualProduct = this.getProduct();
    hasChannelName = actualProduct && typeof actualProduct.channel_name === 'string' && actualProduct.channel_name.trim().length > 0;

    return hasChannelName;
  }

  /**
   * Trigger LD track event
   *
   * @param {*} name
   * @param {*} customFields
   */
  handleLDTrackEvent = (name, customFields = {}) => {
    const { ldClient } = this.commonContext.props;
    if (ldClient && name) {
      ldClient.track(name, customFields);
    }
  }

  getChapterId = () => {
    const chapterId = PathUtils.getQueryParameterbyName(CHAPTER_ID_QUERY_PARAM);

    return chapterId ? decodeURI(chapterId) : null;
  }

  /**
   * GA push for user Access eTextbook from diffrent source
   *
   */
  dispatchEreaderUserPathEvent = () => {
    const {
      AUTHHOME,
      CONSOLE,
      DIRECT,
      DEEPLINK
    } = SOURCE_OF_EREADER_LAUNCH;
    const { flags } = this.commonContext.state;
    const isIntergratedMLMLaunch = CommonUtils.isIntergratedMLMLaunch();
    const product = this.getProduct();
    const businessModelCode = product.getBusinessModelCode();
    let userPath = null;
    const referrerURL = document.referrer;
    if (referrerURL) {
      if (this.isFromDeepLink) { // from deep link
        userPath = DEEPLINK;
      } else if (referrerURL.includes(`${env.CONTENT_HOST}/home`)
      || (this.redirectUrl && this.redirectUrl.includes(env.CONTENT_HOST))
      ) { // from authhome, For testing in local please change env.CONTENT_HOST into local url
        userPath = AUTHHOME;
      } else if (referrerURL.includes(env.CONSOLE_URL)) { // from console
        userPath = CONSOLE;
      }
    } else {
      userPath = DIRECT; // from bookmark or copy paste url
    }
    const isShowAITutorEnabled = CommonUtils.isAITutorEnabled(flags.showAITutor, flags.isAIReviewUser, product.isAITutorSupported);
    const customFields = {
      book_id: CommonUtils.toString(product.bookID),
      business_model_code: CommonUtils.toString(businessModelCode),
      page_id: CommonUtils.toString(this.commonContext.pageId),
      user_path: userPath,
      gpt: (!isIntergratedMLMLaunch && isShowAITutorEnabled) ? 'true' : 'false',
      audio: (!isIntergratedMLMLaunch && product.isAudioBook(product.audioBook)) ? 'true' : 'false',
      channels_miniplayer: (!isIntergratedMLMLaunch && product.channel_name) ? 'true' : 'false',
      is_embedded: isIntergratedMLMLaunch ? 'true' : 'false'
    };
    CommonUtils.dispatchGaEvent(EREADER_CATEGORY, EVENT_EREADEREVENT, EREADER_ACTION_ACCESS_ETEXT, null, customFields);
  }


  /**
   * Function to initial and identify user in braze
   * @param {*} callBack
   */
  IntialiseAndIdentifyBraze = (callBack = () => {}, enableInAppMessage) => {
    const { user } = this.commonContext.props;
    if (!CommonUtils.isIntergratedMLMLaunch() && user && user.id) {
      BrazeUtils.handleEvent(BRAZE_IDENTIFY_USER, user.id, callBack, {}, enableInAppMessage);
      this.commonContext.setState({ brazeInitiated: true });
    }
  }

  /**
   * Function to initialize pendo or update the metadata of pendo
   *
   * @param {*} updateMetaData
   * @param {*} updateOptions
   */
  InitializePendo = (updateMetaData = false, updateOptions = {}) => {
    const { user } = this.commonContext.props;
    let customFields = {};

    if (this.location === AUTH_HOME) {
      const herobanner = getSnapshot(Framework.getStoreRegistry().getStore('herobanner'));
      const { subscriptionType } = this.commonContext.onboardingPlugin.getRecentBookMappedChannel();

      customFields = {
        authHomeUnboxingFlow: subscriptionType,
        authHomePageTemplate: herobanner.bannerType,
        authHomeStudyPrep: CommonUtils.isBundleSubscriber(),
        oneReaderChannelID: herobanner.channelId || NOT_AVAILABLE
      };
    } else if (this.location === EREADER) {
      const product = this.getProduct();
      const businessModelCode = product.getBusinessModelCode();
      customFields = {
        businessModelCode,
        oneReaderChannelID: product.channel_id || NOT_AVAILABLE,
        bookId: product.bookID || product.id || NOT_AVAILABLE,
        isChannelsSubscriber: CommonUtils.isChannelsSubscriber()
      };
    }
    if (!CommonUtils.isIntergratedMLMLaunch() && user && user.id) {
      if (updateMetaData) {
        PendoUtils.updateOptions(user, this.location, { ...customFields, ...updateOptions });
      } else {
        PendoUtils.handleInitialization(user, this.location, customFields);
      }
    }
  }

  /**
   * Returns true if book has AI chatbot
   * @returns
   */
  isAIEnabled = () => {
    const { flags } = this.commonContext.state;
    const product = this.getProduct();

    return CommonUtils.isAITutorEnabled(flags.showAITutor, flags.isAIReviewUser, product.isAITutorSupported);
  }

  onBrazeEventFromReader = (name) => {
    if (name.toLowerCase() === AITutorLoaded.toLowerCase()) {
      this.commonContext.setState({ AITutorloaded: true });
    }
    const updatedBrazeContentCards = this.commonContext.state.brazeContentCards.map((card) => {
      if (card.data?.subscribeToEvent && name && card.data.subscribeToEvent.toLowerCase() === name.toLowerCase()) {
        return { ...card, data: { ...card.data, show: true } };
      }
      return card;
    });
    if (this.commonContext.state.brazeContentCards && this.commonContext.state.brazeContentCards.length > 0) {
      this.commonContext.setState({ brazeContentCards: updatedBrazeContentCards });
    }
  }

  /**
   * Update the pendo meta data from VR callback
   *
   * @param {*} data
   */
  onPendoMetaFromReader = (data) => {
    const { user } = this.commonContext.props;
    if (data && !CommonUtils.isEmpty(data) && data.pendoMetadata) {
      if (!CommonUtils.isIntergratedMLMLaunch() && user && user.id) {
        this.InitializePendo(true, data.pendoMetadata);
      }
    }
  }

  /**
   * Enables archive title feature when
   * 1. Flag - enableArchival = true
   * 2. User books >= archiveWhenUserHasXOrMoreTitles
   * 3. User does not have MULTI subscription
   *
   */
  setEnableTitleArchival = () => {
    const { bookshelf } = this.commonContext.props;
    const { flags } = this.commonContext.state;
    const { enableArchival, archiveWhenUserHasXOrMoreTitles } = flags;
    const books = getSnapshot(bookshelf)?.books;
    let enableTitleArchival = false;

    if (enableArchival && books.length >= archiveWhenUserHasXOrMoreTitles) {
      enableTitleArchival = true;
      this.commonContext.setState({ enableTitleArchival: true });
      HeroBannerPlugin.setEnableTitleArchival(true);
    }

    // HeroBannerPlugin.setHeroBannerBook(true);
    bookshelf.setShowArchiveOrUnarchive(enableTitleArchival);
  }

  /**
   * Function to open component from braze campaign
   * @param {*} component
   */
  openEreaderComponentFromBraze = (component, utmParams = {}) => {
    if (component && BRAZE_EREADER_COMPONENTS[component.toUpperCase()]) {
      const {
        AI_CHATBOT, AUDIO, CHANNELS, STUDY, NOTEBOOK
      } = BRAZE_EREADER_COMPONENTS;
      const { drawerView } = this.commonContext.state;
      const eventData = CommonUtils.isEmpty(utmParams) ? {} : { eventData: utmParams };
      switch (component.toUpperCase()) {
        case AI_CHATBOT:
          this.commonContext.setState({
            openAITutor: {
              isOpen: true,
              ...eventData
            }
          });
          break;
        case AUDIO:
          this.commonContext.setState({
            drawerView: {
              ...drawerView,
              ...eventData,
              drawerState: DRAWER_STATE_OPEN,
              selectedPanel: DRAWER_PANEL_AUDIO
            }
          });
          break;
        case CHANNELS:
          this.commonContext.setState({
            drawerView: {
              ...drawerView,
              ...eventData,
              drawerState: DRAWER_STATE_OPEN,
              selectedPanel: DRAWER_PANEL_CHANNELS
            }
          });
          break;
        case STUDY:
          this.commonContext.setState({
            drawerView: {
              ...drawerView,
              ...eventData,
              drawerState: DRAWER_STATE_OPEN,
              selectedPanel: DRAWER_PANEL_STUDY
            }
          });
          break;
        case NOTEBOOK:
          this.commonContext.setState({
            drawerView: {
              ...drawerView,
              ...eventData,
              drawerState: DRAWER_STATE_OPEN,
              selectedPanel: DRAWER_PANEL_NOTEBOOK
            }
          });
          break;
        default:
          break;
      }
    }
  }

  /**
   * To get AI turor auto open command
   *
   * @param {*} data
   * @returns
   */

  getAiTutorAutoOpenCommands(data) {
    const renderData = !CommonUtils.isEmpty(data) ? data : {};
    const { titles = [] } = renderData;
    let openCommands = null;
    if (titles.length > 0) {
      const { bookID, id } = this.getProduct();
      const bookIdToMatch = bookID || id;
      const result = titles.filter(({ bookId }) => (bookIdToMatch === bookId));
      openCommands = result.length > 0 ? result[0] : null;
    }

    return openCommands;
  }

  /**
   * Gets and returns print page number from query parameter
   *
   * @returns
   */
  getPrintPageNumber = () => {
    const printPageNumber = PathUtils.getQueryParameterbyName(GO_TO_PAGENO_QUERY_PARAM);

    return printPageNumber ? decodeURI(printPageNumber) : null;
  }

  /**
   * Gets and returns source from query parameter
   *
   * @returns
   */
  getSourceFrom = () => {
    const sourceFrom = PathUtils.getQueryParameterbyName(SOURCE_FROM);

    return sourceFrom ? decodeURI(sourceFrom) : null;
  }

  /**
   *  IS printable book or not
   *
   * @returns
   */
  isPrintEnabledForBook() {
    const { configuration, model } = this.getProduct();
    const isPdfConfiguration = !!(configuration?.headerFeatures?.hasPrintLink);
    const isCongifuration = !!(configuration?.enableUserPrinting);
    const isCustomCollections = model === CUSTOM_COLLECTIONS;

    return isPdfConfiguration || isCongifuration || isCustomCollections;
  }

  /**
   * Print page message while print via browser
   *
   * @returns
   */
  getPrintMessage() {
    const message = {};

    if (this.isPrintEnabledForBook()) {
      message.title = this.commonContext.props.language.getMessage('print.printAvailTitle');
      // eslint-disable-next-line max-len
      message.description = this.commonContext.props.language.getMessage('print.printAvailDesc');
    } else {
      message.title = this.commonContext.props.language.getMessage('print.printNotAvailTitle');
      // eslint-disable-next-line max-len
      message.description = this.commonContext.props.language.getMessage('print.printNotAvailDesc');
    }

    return message;
  }

  /**
   * Print message component while print via browser
   *
   * @returns
   */
  renderPrintMessage() {
    const { title, description } = this.getPrintMessage();

    return (
      <PrintMessage
        title={title}
        description={description}
      />
    );
  }

  /**
   * To initiate DeviceManagement On Ereader for HEI
   * If user has GPS subscrioption then this won't initiate again
   *
   */
  initiateDeviceManagementOnEreader() {
    const { user } = this.commonContext.props;
    const product = this.getProduct();
    const businessModelCode = product.getBusinessModelCode();
    if (CommonUtils.isPplusOrIAbookOrHEI(businessModelCode)) {
      Framework.initiateDeviceManagement(user.isImpersonatedId);
    }
  }
}
