/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Translation file for Japanese language
 *
 * @file ja.js
 * @author Manimaran.S
 */
const ja = {
  'authHome.h1': 'Authenticated Home',
  'moremenu.downloadApp': 'Download the app',
  'moremenu.downloadAppAlreadyDownload': '%Click here% to download the app on another device and automatically sign in or to use your unique QR code to securely sign in on the app you\'ve already installed.',
  'moremenu.downloadAppNotDownload': '%Click here% to download the app and securely sign in with your unique QR code.',
  'channel.bundleTitle': 'お客様の他のコース向け学習および試験準備パックへアクセスする',
  'channel.noBundletitle': '学習および試験準備を他のコースの学習に活用しましょう',
  'onboard.welcome': 'Pearson+へようこそ',
  'onboard.welcomeCourse': 'お客様は <span>{COURSE_NAME}</span> などにアクセスするのに最適な場所にいます。',
  'onboard.welcomeCourseList': 'お客様のご購入は次の通りです：',
  'onboard.next': '次へ',
  'onboard.studyPlan': 'Custom study plan created!',
  'onboard.addCourseAnother': '他のコースを追加しますか？',
  'onboard.addCourseWithoutEtextBundle': '学習および試験準備を使用してコースを追加する',
  'onboard.doYouWantOtherCourse': '他のコースを追加しますか？',
  'onboard.purchase': 'PearsonのeTextブックを購入する必要があります',
  'onboard.addCourseWithoutEtext': 'eTextブックなしでコースを追加する',
  'onboard.mayBeLater': '後でする',
  'onboard.aboutCourse': 'コースについて教えてください',
  'onboard.close': 'Close',
  'course.addTitle': 'コースを追加',
  'bookshelf.TITLE': '私の本棚',
  'common.LOGOUT': 'ログアウト',
  'common.GO_HOME': 'ホームに移動',
  'common.PURCHASE': '購入',
  'courses.WELCOME': 'こんにちは、{userName}さん。{courseName}コースへようこそ。',
  'courses.action.SHOW_ASSIGNMENTS': 'アサインを表示',
  'courses.toc.PUBLISH_SUCCESS': '成功！',
  'courses.toc.PUBLISH_MESSAGE': '生徒が次にコンテンツを開いたときに、変更が表示されます。',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'サブスクリプションが見つかりません',
  'errors.SUBSCRIPTION_GENERIC_DESC': `アカウントには、このタイトルへのアクセスが含まれていません。このメッセージを誤って受け取ったと思われる場合は、
      Pearsonテクニカルサポートにお問い合わせください。`,
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'サブスクリプションが見つかりません',
  'errors.SUBSCRIPTION_REGISTRATION_DESC': 'サブスクリプションにはeTextへのアクセスは含まれていませんが、クレジットカードを使用して今すぐサブスクライブできます。',
  'foxit.ABOUT': '詳細',
  'foxit.COPY_RIGHT': 'PDF Reader Powered by Foxit. Copyright (C) 2003-2019 by Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonPrivacyPolicy.html',
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonEULA.html',
  'menu.GET_THE_APP_DOWNLOAD': 'モバイルアプリのダウンロード',
  'menu.CONTACT_SUPPORT': 'サポートに連絡',
  'menu.ABOUT': '詳細',
  'menu.FOXIT': 'Powered by Foxit',
  'menu.TERMS_OF_USE': '利用規約',
  'menu.PRIVACY_POLICY': 'プライバシー方針',
  'menu.SIGN_OUT': 'サインアウト',
  'menu.PRINT_PAGE': 'ページを印刷',
  'menu.GET_LOOSE_LEAF': 'ルーズリーフを取得',
  'menu.AVAILABLE': 'こちらも利用可能',
  'menu.PRINT_VERSION': '印刷版をご希望ですか？',
  'menu.COPY_RIGHT': 'Copyright \u00A9 {year} Pearson Education Inc. All Rights Reserved.',
  'menu.tooltip.SCHEDULE': 'スケジュール',
  'print.FOOTER_MESSAGE': 'Printed by {fullName}({email}) on {currentDate}.',
  'print.WATERMARK': 'Copyright © {year} Pearson Education',
  'product.WELCOME': 'こんにちは、{userName}さん。',
  'device.SWAPPED_ALREADY': '今月はすでに交換しています',
  'device.SWITCH_DEVICE': '現在のデバイスに切り替えますか？',
  'device.SWITCH_ONCE': '切り替えができるのは月に1回のみのため、元に戻すことはできません。',
  'device.SEAT_TAKEN': '席がいっぱいです',
  'device.DISCONNECT_DEVICE': 'ログインできるデバイスの台数は3台のみです。続行するには、他のデバイスの接続を解除してください。',
  'device.ALERT_TEXT': 'なお、デバイスの交換は1ヶ月に1回しかできません。',
  'device.YOUR_MOJO': 'Mojoにアクセスしています',
  'device.DISCONNECT': '接続解除',
  'device.CONTINUE': '続ける',
  'device.HEADER': '他のセッションを閉じますか？',
  'device.CLOSE': 'ホームに戻る',
  'device.CODE': 'ログアウトしたところから続行したい場合は、{maskedEmail}へ送信された認証コードを入力してください。',
  'device.TIMER': 'こちらにコードを入力できる時間は{time}です',
  'device.RESEND': '',
  'device.RESEND_NAME': '',
  'device.CONTACT': '',
  'device.CONTACT_SUPPORT': '',
  'device.CONTACT_EMAIL': '',
  'device.CONTACT_FAQ': '',
  'device.INCORRECT': '不正確です—コードをもう一度確認するか、再送信してください。',
  'device.MANY_ATTEMPTS': '試行回数の上限を超えたため、コードの再送信をお試しください',
  'device.EXPIRED': '時間切れになりました。コードの再送信をお試しください',
  'device.ACCESS_HEADER': '認証されました！',
  'device.ACCESS_DESC': 'セッションが認証されました。セッション中にパスワードを更新しますか？',
  'device.ACCESS_CHANGE_PASSWORD': 'パスワードを変更',
  'device.ACCESS_RESET_PASSWORD': 'パスワードをリセット',
  'device.THREATONE_TITLE': '問題はございませんか？',
  'device.THREATONE_DESC': 'お客様のアカウントで不審なアクティビティが検出されました',
  'device.THREATONE_DESC_TWO': 'パスワードを変更されることをおすすめします。',
  'device.MAY_BE_LATER': '後でする',
  'device.OKAY': 'OK',
  'device.ACCOUNT_LOCK_DESC': '安全性を保つために、お客様をログアウトしました。お客様のアカウントに関連付けられたメールアドレスに、パスワードリセット用リンクを送信しました。',
  'device.ACCOUNT_LOCK_TITLE': 'お客様のアカウントで不審なアクティビティが検出されました',
  'device.ACCOUNT_LOCK_TIMER': 'ログアウトまであと {time}',
  'menu.myAccount': '私のアカウント',
  'menu.helpCenter': 'ヘルプセンター',
  'menu.preferencesCenter': 'プリファレンスセンター',
  'menu.HELP_SUPPORT': 'ヘルプ&サポート',
  'menu.FAQS': 'よくある質問（FAQ）',
  'menu.CHAT_WITH_SUPPORT': 'サポートとチャット',
  'menu.QUICK_WALKTHROUGH': 'クイックウォークスルー',
  'menu.LEGAL': '法的情報',
  'menu.PRIVACY_NOTICE': 'プライバシー通知',
  'menu.TITLE_INFO': 'タイトル情報',
  'menu.PEARSON_ACCESSIBILITY': 'ピアソンアクセシビリティ',
  'menu.DEVICE_WARNING_MESSAGE': '{deviceLimit}台登録可能です。月に1台ずつ交換可能です。',
  'menu.ACCESSIBILITY': 'アクセシビリティ',
  'menu.ACCESSIBILITY_SUPPORT': 'アクセシビリティサポート',
  'menu.DEVICES': 'デバイス',
  'menu.THIS_DEVICE': '最近のデバイス',
  'menu.MOJO_APP_INFO': 'オフラインでPearson+をご利用いただくには、お手持ちの携帯電話やタブレットのアプリストアにアクセスしてください。',
  'menu.DEVICE_LABEL': 'デバイス',
  'navigation.PAGE': 'ページ',
  'launchcode.CREATEBY': '作成者：',
  'launchcode.sentvia': '送信元:',
  'launchcode.CODEINLAST': '直近7日間のコード：',
  'launchcode.TIMESENT': '送信時刻：',
  'launchcode.ACCOUNTEMAIL': 'アカウントのメールアドレス：',
  'launchcode.LAUNCHDIALOGTITLE': 'OTPコードログ',
  'menu.OTP_CODES': 'OTPコード',
  'menu.LAUNCH_CODE': 'コードログの立ち上げ',
  'menu.SEND_CODE': '電子メールでユーザーの新しいコードを送信',
  'menu.SEND_CODE_PHONE': 'テキストメッセージでユーザーの新しいコードを送信',
  'menu.COMMUNITY_GUIDELINES': 'コミュニティのガイドライン',
  'textmessage.TITLE': '携帯番号を入力する',
  'textmessage.PHONE_NUMBER': '電話番号',
  'textmessage.SEND_CODE': 'コードを送信',
  'textmessage.INVALID_NUMBER': '電話番号が無効です。',
  'textmessage.SUCCESS_MESSAGE': 'リンクが送信されました',
  'search.searchPlaceHolderPPlus': 'Search by ISBN, Title, or Author',
  'search.searchPlaceHolderEText': 'リストを検索',
  'remove.title': 'タイトルを削除しますか？',
  'remove.subTitle': '後でいつでも元に戻すことができます—検索を使用して再度追加してください。',
  'remove.buttonText': '削除',
  'footer.downloadMobileApp': 'モバイルアプリのダウンロード',
  'footer.termsOfUse': '利用規約',
  'footer.privacy': 'プライバシー',
  'footer.cookies': 'Cookie',
  'footer.doNotSellMyPersonalInformation': '個人情報を販売しないでください',
  'footer.accessibility': 'アクセシビリティ',
  'footer.patentNotice': 'プライバシー通知',
  'footer.copyrights': '無断複写・複製・転載を禁ず。',
  'channel.videos': 'ビデオ',
  'channel.questions': '質問',
  'channel.descriptionA': 'チャンネルで読み物を追加しましょう。',
  'channel.descriptionB': 'チャンネルで難しいトピックのヘルプを得ましょう。',
  'channel.openchannel': 'チャンネルを開く',
  'hero.continueReading': '読書を続ける',
  'hero.openBook': 'ブックを開く',
  'hero.channel.open': 'チャンネルを開く',
  'hero.addATitle': 'タイトルを追加',
  'hero.toc.defaultPlacehoder': '--- select a topic ---',
  'hero.viewInactiveTitles': '無効なタイトルを表示',
  'library.marketingCard': {
    title: 'Choose from over 2,000 eTextbooks',
    subTitle: 'with audio, flashcards, notes, and more.',
    primaryButton: {
      text: 'eTextブックを見つける'
    },
    secondaryButton: {
      text: 'View all titles'
    }
  },
  'hero.mappedChannelV2': {
    ariaLabel: 'channel',
    heading: 'Recommended study help Channel',
    callForAction: {
      text: 'Open'
    }
  },
  'hero.openEtextBook': 'eTextブックを開く',
  'hero.openEtextBookV1': 'eTextブックを開く',
  'hero.heroHeading': 'なぜならあなたは <span>{channel_title}</span>を学習中だからです',
  'hero.heroHeadingForBundleUser': '学習および試験前準備パック',
  'hero.study.help': '学習サポートを手に入れましょう',
  'hero.study.jumpBackIn': 'ジャンプして戻る',
  'hero.study.numOfUsers': '現在 <b>{num_of_users}</b> 人の生徒がチャンネルを使用しています。',
  'hero.heroBook.sectionHeading': 'コースをスムーズに進めましょう',
  'hero.heroBook.channelsSectionHeading': '新しい学習セッションを始めましょう',
  'hero.heroBook.sectionHeadingNewUser': '学習サポートはこちら',
  'hero.heroBook.sectionSubHeading': 'コースに合わせた演習問題とビデオの説明で難しいトピックをわかりやすく覚えましょう。',
  'hero.heroChannel.cardsHeading': '中断したところから続けましょう',
  'hero.marketingcard.subheading': '難しいコンセプトをわかりやすく説明した、追加の演習問題があるビデオで、もっと効率的に学びましょう。すべてコースにあわせて提供されます。',
  'hero.marketingcard1.copy.title': 'ビデオで学習しましょう',
  'hero.marketingcard1.copy.description': '難しいトピックをわかりやすく教えてくれる簡単なビデオ説明を見ましょう。',
  'hero.marketingcard2.copy.title': '演習問題',
  'hero.marketingcard2.copy.description': '数千問の模擬試験問題やビデオ教材で試験準備をしましょう',
  'hero.marketingcard3.copy.title': '経験豊富なサポートを得ましょう',
  'hero.marketingcard3.copy.description': '質問を投稿してその分野の専門の先生から回答を得られます。',
  'hero.popularTopics.heading': 'このチャンネルのその他の人気のあるトピック',
  'hero.popularChannels.heading': '人気のあるチャンネル',
  'hero.popularChannels.headingv1': '他のコースを受講しますか？',
  'hero.popularChannels.description': 'この人気チャンネルで学習ヘルプを手に入れましょう。',
  'hero.popularChannels.descriptionv1': 'チャンネルを選択すると他のコースでサポートを得られます。',
  'hero.popularChannels.descriptionv2': '人気のある学習サポートトピックを見てみましょう。',
  'hero.startReading': 'リーディングを開始',
  'hero.practice': '演習',
  'hero.noTitleNoChannel.sectionHeading': '記録的な速さで試験の準備をしましょう',
  'hero.herobook.mappedChannelHeading': 'このチャンネルでのおすすめの学習サポート',
  'libraryL2.myBooks': 'My Books',
  'libraryL2.archived': 'Archived',
  'libraryL2.back': '戻る',
  'hero.heroHeadingNoMappedChannel': 'Based on what you\'re studying',
  'hero.practiceProblem': 'Practice Problem',
  'nativeAppBanner.drawer.title': 'Open in ...',
  'nativeAppBanner.drawer.pearsonApp': 'Pearson+ App',
  'nativeAppBanner.banner.title': 'Pearson+ app',
  'nativeAppBanner.banner.description': 'Learn on the go',
  open: 'Open',
  continue: 'Continue',
  'hero.promotionalcard.list1': '難しいトピックをわかりやすく教えてくれる簡単なビデオ',
  'hero.promotionalcard.list2': '数千問の模擬試験問題',
  'hero.promotionalcard.list3': 'その分野の専門の先生からの回答を得る',
  'hero.studyandexamprep': '学習および試験準備',
  'hero.SeeAllStudyOptions': 'See all study options',
  'hero.tocLabel': 'View content related to your eTextbook',
  'hero.herobook.mappedChannelHeadingV1': 'Recommended videos and practice questions',
  'hero.popularTopics.headingV1': 'Other popular topics',
  'hero.heroHeadingV1': 'For <span>{replace_value}</span>',
  'hero.learnmore': '詳細を見る',
  'hero.subscriptionStatusTitle': 'Pearson+サブスクリプションのステータス',
  'hero.eTextbook': 'eTextブック',
  'hero.mylabOrMastering': 'MyLab or Mastering',
  'hero.goToPage.label': 'ページへ移動する',
  'subscription.eTextbook': 'eTextブック',
  'subscription.mylab': 'MyLab or Mastering',
  'subscription.studyBundle': '学習および試験前準備パック',
  'subscription.studyNoBundle': '学習および試験準備',
  'subscription.tryItOut': 'Try it out',
  'print.printAvailTitle': 'Want to print this page? Here’s how',
  'print.printAvailDesc': 'In your eTexbook, select the account icon in the top right. From the options, choose “Print page” and then choose your print settings.',
  'print.printNotAvailTitle': 'The print option for this eText is not enabled.',
  'print.printNotAvailDesc': 'For convenient offline access, please download your title through the Pearson+ mobile app and enjoy the flexibility of studying anytime, anywhere!',
  'hero.continueStudying': '学習を続ける',
  'hero.header.cta': '{hero_header_cta_text} study tools',
  'hero.marketing.title': 'eTextブックを見つける',
  'hero.marketing.subTitle': 'Choose from over 2,000 eTextbooks with audio, flashcards, notes, and more. Only $10.99/mo for a 4-month term.',
  'hero.allyourcourses': 'all your courses',
  'hero.popularchannels.desc': 'Get study help with these top subjects',
  'hero.removecourse.title': 'ダッシュボードからコースを削除しますか？',
  'hero.removecourse.description': 'You can always add this course back to your dashboard by clicking "Add course".',
  'hero.removecourse.primaryCtaText': '削除',
  'hero.removecourse.secondaryCtaText': 'キャンセル',
  'hero.subscriptionStatusTitle1': 'What\'s Available for you in Pearson+',
  'hero.emptystate.cta': 'Search eTextbooks',
  'hero.watch_it_again': 'Watch it again',
  'hero.mondly.learnLang.title': '新しい言語を無料で学ぶ',
  'hero.mondly.learnLang.title2': '新しい言語を無料で学ぶ!',
  'hero.mondly.subTitle': 'Pearson eTextブックユーザーならMondly by Pearsonを3か月無料でご利用いただけます。',
  'hero.mondly.description': 'ほとんどの語学学習アプリは英語で学ぶようになっています。しかし、お客様の母語から学ぶのが一番良いため、Mondlyは41の言語のいずれかで学ぶことが可能です。',
  'hero.mondly.marketing.bestApp': 'Best App',
  'hero.mondly.marketing.appStore': 'App Store',
  'hero.mondly.marketing.appYear': 'App of the Year',
  'hero.mondly.marketing.fbStart': 'Facebook FbStart - 2017 EMEA Winner',
  'hero.mondly.marketing.editorChoice': 'Editor Choice',
  'hero.mondly.marketing.googlePlay': 'Google Play',
  'header.menu.learnLanguage': '語学学習',
  'hero.subscriptionStatusTitle2': 'お客様のPearson+のリソース',
  'hero.extractingExamDetails': '試験の詳細の抽出',
  'hero.finishingTheProcess': 'プロセスの終了',
  'hero.hereWhatWeGotFromYourSyllabus': 'シラバスから得た情報は次のとおりです',
  'hero.school': '学校：',
  'hero.class': 'クラス：',
  'hero.textbook': 'テキスト：',
  'hero.examSchedule': '試験スケジュール：',
  'hero.confirm': '確定',
  'onboard.studyMaterials': '科目を選択すると、カスタマイズされた学習教材を含むダッシュボードが作成されます。',
  'hero.personalizedStudyPlan': 'AIを活用して、お客様に合わせた学習プランをご提供します。',
  'hero.welcomeToP': 'P+へようこそ！',
  'hero.learnYourWayAround': '今すぐちょっと時間をかけて操作方法を学びましょう。または、後ほどアカウント設定で実行してください。',
  'hero.weveCreatedADashboard': 'ここにコース用のダッシュボードを作成しました。',
  'hero.youCanAddYourPearsonCourse': 'お客様ご自身でPearson以外のコースを追加し、学習教材を無料で試すこともできます。',
  'hero.getExamreadyWithVideoLessons': 'お客様のコースに合わせてカスタマイズされたビデオレッスンと演習セットで試験に備えましょう。',
  'hero.youreTexbook': 'お客様のeTextブックとそのすべてのツールがここにあります。',
  'hero.VideosAndPractice': '学習と試験準備で、もっと多くのビデオと演習問題をいつもご利用いただけます。',
  'hero.pleaseSelectTheChapters': 'この試験で網羅されているチャプターを選択してください：',
  'hero.doYouWantToAddACourse': 'コースを追加しますか？',
  'hero.whatsAvailableForYou': 'お客様がご利用可能なコース',
  'hero.whatsAvailableForYouInPearson': 'Pearson+でご利用可能なもの',
  'hero.startStudying': '学習を開始する',
  'hero.tableOfContents': '目次',
  'hero.search': '検索',
  'hero.notes': 'メモおよびハイライト',
  'hero.mondly.startLearningCTA': '学習を開始する',
  'hero.unlockYourStudyExamPrep': '学習および試験準備パックを今すぐアンロックします！',
  'hero.getExamreadyForYou': '学習および試験準備を使用して、他のコースの試験に備えましょう。',
  'hero.weVideoLessons': 'お客様のニーズに合わせてカスタマイズされたビデオレッスンと演習セットをご用意しています。',
  'hero.tryForFree': '無料で試してみる',
  'hero.heroBook.sectionSubHeadingV1': 'コースに合わせた演習問題とビデオの説明で難しいトピックをわかりやすく覚えましょう。',
  'hero.addCourseUsingStudyExamPrep': '学習および試験準備を使用してコースを追加する',
  'hero.for': '向け',
  'header.menu.moreAtPearson': 'Pearsonの詳細を見る',
  'hero.studyExamPrepPackForAllCourse': 'お客様の全てのコース向け学習および試験準備パック',
  'hero.myETextbooks': '私のeTextブック',
  'header.continueInTheApp': 'アプリで続ける',
  'hero.goToMyLabMastering': 'MyLab/Masteringへ移動する',
  'hero.learnTxt': '学習',
  'hero.aITutorTxt': 'AIチューター',
  'hero.exploreTxt': '探索する',
  'hero.studyExamPrepAllYourCourses': 'お客様の全てのコース向け学習および試験準備',
  'hero.examSchedules': '試験のスケジュール',
  'hero.examSchedules2': '試験のスケジュール',
  'hero.uploadYourSyllabus': 'お客様のシラバスをアップロードする',
  'hero.addAnExam': '試験を追加',
  'hero.UploadYourSyllabus.studyExamPrepPack': 'お客様の学習および試験準備パックを最大限に活用するためのシラバスをアップロードする',
  'hero.UploadYourSyllabus.studyExamPrep': '学習および試験準備の効果を最大に高めるためにお客様のシラバスをアップロードする',
  'hero.getRecommendationsOnWhat': '何をいつ学ぶかについておすすめをもらう',
  'hero.noSyllabusSharingExamSchedule': 'シラバスがありませんか？お客様の試験スケジュールの共有も可能です',
  'hero.selectDropSyllabusFile': 'PDFまたはDOCX形式のシラバスファイルを選択またはドロップ',
  'hero.uploadTxt': 'アップロード',
  'hero.examName': '試験名',
  'hero.completeTxt': '完了',
  'hero.exactTopicsChapter': '後で各チャプターの正確なトピックを指定できるようになります。',
  'hero.uploadingDocument': 'ドキュメントのアップロード',
  'hero.activatingAIComponents': 'AIコンポーネントの有効化',
  'hero.extractingClassSchoolData': 'クラスと学校のデータの抽出',
  'hero.extractingTextbookChapters': '教科書のチャプターの抽出',
  'hero.insideStudyExamPrepPack': '学習および試験準備パック内',
  'hero.userSubsGuidence': '試験スケジュールに合わせて調整されたビデオレッスン、学習プラン、演習セットにお好きなだけアクセスできます。さらに、質問をして、専門家からフィードバックを受けることもできます。',
  'hero.examPrepTxt': '試験準備',
  'hero.extractingDataFromSyllabus': 'シラバスからデータを抽出しています…',
  'hero.myLabMastering': 'MyLab/Mastering',
  'hero.dateFormat': 'DD/MM/YY',
  'hero.insideStudyExamPrep': '学習および試験準備内',
  'hero.testVideoLessons': '試験スケジュールに合わせたビデオレッスン、学習プラン、演習セットを試してみましょう。',
  'header.qrCode.subHeaderTxt': 'このQRコードを使用すると、モバイルデバイスのPearson+アプリにすばやく安全にサインインできます。',
  'header.qrCode.dontHaveApp': 'アプリをお持ちではありませんか？',
  'header.qrCode.footerTxt': 'このコードをスキャンすると、アプリストアまたはGoogle Playにリダイレクトされます。アプリがダウンロードされたら、もう一度スキャンしてサインインしてください。',
  'header.qrCode.errorMsg': 'QRコードが読み込めませんでした。閉じてやり直してください。',
  'hero.yourETextbook': '自分のeTextブック',
  'onboard.addCourseWithEtextBundle': '学習および試験準備パックを使用してコースを追加する',
  'onboard.disableTooltipText': 'All Study & Exam Prep courses are added!',
  'subscriptionstatus.bundlecta': 'Access Study & Exam Prep Pack',
  'subscriptionstatus.nonbundlecta': '学習および試験準備へアクセスする',
  'channel.genericBundle': '学習と試験準備パックを他のコースの学習に活用しましょう',
  'channel.relatedBundle': 'また、学習および試験準備パックを他のコースの学習に活用しましょう',
  'onboard.courseAdded': 'Course added!',
  'hero.flashcards': 'Flashcards',
  'aichat.title': 'AI Study Tools',
  'aichat.placeholder': 'Which topic or concept from your eTextbook can I explain for you?',
  'aichat.charDesc': 'Ask me anything related to <b>%BOOK_TITLE%</b>.'
};

export default ja;
