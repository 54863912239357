/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2022 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Authhome container
 *
 * @file AuthHomeContainer.jsx
 * @author Vignesh Selvam
 */

import React, { Component, createRef, Fragment, Suspense } from 'react';
import { inject, observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import PropTypes from 'prop-types';
import Framework, { shapes } from '@greenville/framework';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import Footer from '../authhome/Footer';
import Container from '../../common/components/Container';
import LoadingContainer from '../../common/components/LoadingContainer';
import CommonPlugin from '../../common/plugins/CommonPlugin';
import BannersPlugin from '../authhome/plugin/BannersPlugin';
import HeroBannerPlugin from '../authhome/plugin/HeroBannerPlugin';
import BrazePlugin from '../braze';
import BrazeTemplatePlugin from '../braze/BrazeTemplatePlugin';
import TemplatesPlugin from '../authhome/plugin/TemplatesPlugin';
import StudyHelpPlugin from '../authhome/plugin/StudyHelpPlugin';
import Bookshelf from '../bookshelf/model/Bookshelf';
import HeroBannerModel from '../authhome/model/HeroBanner';
import LdUserHash from '../authhome/model/LdUserHash';
import userdetailsModel from '../userDetails/model/UserDetails';
import LastLocation from '../bookshelf/model/LastLocation';
import ChannelRecommendationsByBook from '../authhome/model/ChannelRecommendationsByBook';
import ChannelRecommendationsByChannel from '../authhome/model/ChannelRecommendationsByChannel';
import ProductToken from '../products/model/ProductToken';
import env from '../../common/env';
import * as constants from '../../common/constants';
import ErrorCard from '../../common/components/ErrorCard';
import '../../assets/styles/glossaryPopup.css';
import CommonUtils from '../../common/utils/CommonUtils';
import PathUtils from '../../common/utils/PathUtils';
import '../bookshelf/components/BookshelfStyles.scss';
import '../authhome/AuthHomeStyles.scss';
import BackToHome from '../authhome/BackToHome';
import AccountLock from '../../common/components/AccountLock';
import AccountWarning from '../../common/components/AccountWarning';
import RemoveTitleDialog from '../../common/components/RemoveTitleDialog';
import {
  ChannelsLoader,
  CommonLoader,
  HeaderLoader,
  CourseSectionLoader
} from '../authhome/Loader/ComponentLoader';
import FooterIllustration from '../authhome/FooterIllustration';
import BrazeContainer from '../braze/BrazeContainer';
import BrazeUtils from '../../common/utils/BrazeUtils';
import HeaderBanner from '../../common/components/banners/headerbanners/HeaderBanner';
import NativeAppBannerPlugin from '../../common/plugins/NativeAppBannerPlugin';
import OnboardingPlugin from '../onboarding';
import CoursePlugin from '../authhome/plugin/CoursePlugin';
import QRCodeModal from '../../common/components/qrcodemodal/QRCodeModal';
import L2Modal from '../../common/components/modalforl2/L2Modal';
import HeroDashboardPlugin from '../authhome/plugin/HeroDashboardPlugin';
import LoadingCard from '../authhome/Loader/LoadingCard';
import CourseCollection from '../authhome/model/CourseCollection';
import MLMHeroDashboardPlugin from './plugin/MLMHeroDashboardPlugin';
import AuthhomeAIStudyToolPlugin from '../authhomeAIStudyTool/plugin';

const AuthHomeHeader = CommonUtils.lazyWithPreload(() => import('@authhome/header')
  .then(module => ({ default: module.AuthHomeHeader })));
const LibraryExpand = CommonUtils.lazyWithPreload(() => import('@authhome/library')
  .then(module => ({ default: module.LibraryExpand })));
const LibraryExpandV1 = CommonUtils.lazyWithPreload(() => import('@authhome/library')
  .then(module => ({ default: module.LibraryExpandV1 })));
const CourseSection = CommonUtils.lazyWithPreload(() => import('@authhome/library')
  .then(module => ({ default: module.CourseSection })));
const ChannelCarouselContainer = React.lazy(() => import('../authhome/ChannelCarouselContainer'));
const MondlyLanguageContainer = React.lazy(() => import('../authhome/MondlyLanguageContainer'));
const MondlyLanguageCatlogContainer = React.lazy(() => import('../authhome/MondlyLanguageCatlogContainer'));
const TurboContainer = React.lazy(() => import('../authhome/TurboContainer'));

@inject('asset', 'user', 'userdetails', 'language', 'bookshelfStatus', 'bookshelf', 'userStatus', 'userpreferencestatus', 'productToken', 'lastLocationStatus', 'eligiblityStatus', 'ldUserHash', 'herobanner', 'channelRecommendationsByBook', 'channelRecommendationsByChannel', 'coursecollection')
@observer
class MLMAuthHomeContainer extends Component {
  constructor(props) {
    super(props);
    this.brazePlugin = new BrazePlugin(this);
    this.libraryContainer = createRef();
    this.channelsContainer = createRef();
    this.mondlyContainer = createRef();
    this.commonPlugin = new CommonPlugin(this);
    this.brazeTemplatePlugin = new BrazeTemplatePlugin(this);
    this.templatesPlugin = new TemplatesPlugin(this);
    this.bannerPlugin = new BannersPlugin(this);
    this.heroBannerPlugin = new HeroBannerPlugin(this);
    this.heroDashboardPlugin = new HeroDashboardPlugin(this, constants.MLM_AUTH_HOME);
    this.mlmHeroDashboardPlugin = new MLMHeroDashboardPlugin(this);
    this.authhomeAIStudyToolPlugin = new AuthhomeAIStudyToolPlugin(this, constants.MLM_AUTH_HOME);
    this.studyHelpPlugin = new StudyHelpPlugin(this);
    this.nativeAppBannerPlugin = new NativeAppBannerPlugin(this);
    this.onboardingPlugin = new OnboardingPlugin(this);
    this.coursePlugin = new CoursePlugin(this, constants.MLM_AUTH_HOME);
    this.userDetail = {};
    this.isLoadedFromMondly = false;
    this.isGhostAccount = CommonUtils.isGhostAccount();
    this.userPreferences = {};
    this.bookstoreSurveyShown = false;
    this.displayOrderData = [];
    this.usersCollections = [];
    this.state = {
      coursecollectionFetched: false,
      dataFetched: false,
      isIgnored: false,
      threatLevel: 0,
      LDUserFlagsFetched: false,
      isHidden: false, //to be removed
      noOfDays: 0,
      authHomeExpandContainer: '',
      isSearched: false,
      booksData: [],
      searchKeyWord: '',
      displayOrderFromApi: [],
      removeTitleOpen: false,
      removeBook: null,
      userFirstLogin: true,
      seeAllView: false,
      templateDataFetch: false,
      showSearch: !CommonUtils.isHEIUser() && !this.isGhostAccount,
      headerMenuNavData: [],
      onClickFromHeaderNavMenu: false,
      actitveNavHeaderTitle: '',
      flags: {
        enableOnBoardClose: false,
        enableGlobalNavigation: false,
        enableBrazeAuthhome: false,
        enableArchival: true,
        archiveWhenUserHasXOrMoreTitles: 6,
        enableChannelLearnRedirection: false,
        enableNativeAppPromoBanners: false,
        enableHeroBannerVariant2: false,
        enableGoToPage: false,
        showHEIUserContent: false,
        enableQRcode: false,
        enableAuthHomeOnBoard: false,
        enableMLMAuthHomeRedirection: false,
        enableFreeResponse: true,
        enableParagraph: false,
        showAITutor: false,
        isAIReviewUser: false,
        enableTOCPrintPage: false,
        enableMathMLKeyboard: false,
        enableAITutorOnAuthHome: false,
        enableProblemSolveFeature: false,
        AIEnableMarkdown: false,
        topicTimeOut: constants.TOPIC_TIMEOUT,
        enableStreamingAPI: true,
        delayMessageTimerInSecs: constants.DELAY_TIMER_IN_SECS,
        enableAIShowMore: false,
        enableAISleepMode: true,
        showAISleepModeAB: true,
        enableSummaryContinuation: false,
        enableExplainQuizOptions: 1,
        AIChatPlaceHolderText: '',
        enableTopicInQuizFlow: '',
        ChannelsVideoScore: 10,
        showUserHistory: false,
        enableAIShortAnswer: {},
        hideChannelsPopout: false,
        showRecommendedPrompts: false,
        AIBerlinTurnOffSummary: [],
        AIBerlinTurnOffPractice: []
      },
      brazeContentCards: [],
      isUserPreferenceFetched: false,
      // TODO: Need to check on this implementaiton with respect to performance
      studyChannelBooks: undefined,
      brazeInitiated: false,
      brazeContentCardFetched: false,
      brazeInAppMsgFetched: false,
      activeLibraryL2Tab: constants.LIBRARYL2_TABS.MY_BOOKS,
      enableTitleArchival: false,
      headerBannerData: null,
      // eslint-disable-next-line react/no-unused-state
      nativeAppBannerConfig: {
        showDrawer: false,
        showBanner: false,
        showBannerClose: false
      },
      // eslint-disable-next-line react/no-unused-state
      openOnBoard: false,
      // eslint-disable-next-line react/no-unused-state
      onboardSlideData: [],
      // eslint-disable-next-line react/no-unused-state
      onboardGotoSlide: 0,
      // eslint-disable-next-line react/no-unused-state
      showOnBoardClose: false,
      courseList: [],
      modalProps: {
        open: false,
        qrCode: '',
        isQRCodeMsg: ''
      },
      enableHeaderL2Tabs: true,
      activeHeaderL2Tab: '',
      l2ModalOpen: false,
      drawerOpen: false,
      toggleState: { left: false },
      // eslint-disable-next-line react/no-unused-state
      openBotViaGlossary: false,
      // eslint-disable-next-line react/no-unused-state
      glossaryText: '',
      // eslint-disable-next-line react/no-unused-state
      openBotContainer: false
    };
    this.chatBotOpen = PathUtils.getQueryParameterbyName('openChat');
    this.canadianProxy = PathUtils.getQueryParameterbyName('cp');
    document.body.classList.add('authHomePearsonPlus');
    this.authHomeStackOrderData = {};
    this.templateDataFetch = false;
    this.onLoadContainer = createRef(null);
    this.combineStatusSuccess = {
      // combination of userProfile and BookShelf call and Popuplar success
      userProfileAndBookShelfAndPopuplar: false,
      // combination of userProfile and BookShelf call success
      userProfileAndBookShelf: false,
      // combination of userProfile and LD call success
      userProfileAndLD: false,
      // userProfile fetched
      userProfileBookShelfAddCourseFetched: false,
      // bookshelf data fetched
      bookShelf: false,
      // LD and currrent lang success
      langAndLD: false,
      // braze and user and book
      brazeAnduserProfileAndBookShelf: false,
      // braze in app msg and user book
      brazeInAppAnduserProfileAndBookShelf: false,
      // user and bookshelf and channelbybook
      userProfileAndBookShelfAndChannelByBook: false,
      // brazeinit and user and book
      brazeInitiatedAnduserProfileAndBookShelf: false,
      // bookshelf data fetched
      bookShelfAndLD: false,
      // init search last loction call after bookshelf api response
      lastlocationDataRequested: false,
      // On piSession and LD fetch
      piSessionAndLDFetch: false,
      // combination of userProfile, BookShelf and user pref call success
      userPrefAndHeroSet: false,
      // Book and Popuplar success
      bookShelfAndPopuplar: false
    };
    BrazeUtils.initializeBrazeCallBackListener();
  }


  componentDidMount() {
    const {
      user, bookshelf, match
    } = this.props;
    const {
      params: {
        expandContainer
      }
    } = match;
    Framework.getEventManager().on(constants.BRAZE_IN_APP_MESSAGE_CARD_FETCHED, ()=> {
      this.setState({ brazeInAppMsgFetched: true });
    });
    Framework.getEventManager().on(constants.LD_USER_HASH_FETCHED, () => {
      this.updateLaunchDarklyUser();
    });
    this.authHomeInit();
    Framework.getEventManager().on(constants.LASTLOCATION_DATA_REQUESTED, ({ bookIds, bookshelfData }) => {
      if (!this.combineStatusSuccess.lastlocationDataRequested) {
        this.combineStatusSuccess.lastlocationDataRequested = true;
        CommonUtils.pushGlobalParams();
        // Push telemetry Global params and user telemetry events only once on page load when marin response is ready
        if (!bookshelf.isError) {
          this.pushTelemetry(user);
        }
      }
    });

    Framework.getEventManager().on(constants.DISPLAY_ORDER_CONTENT_SUCCESS, (data) => {
      const { enableGlobalNavigation } = this.state.flags;
      if (!CommonUtils.isEmpty(data)) {
        this.authHomeStackOrderData = data;
        const returnStackData = data;
        this.displayOrderData = [];
        const stackProperty = ['heroView', 'library', 'channel', 'mondly', 'turbo', 'procourse'];
        stackProperty.forEach((property) => {
          if (returnStackData[property]) {
            this.displayOrderData.push(returnStackData[property]);
          }
        });
        this.displayOrderData.sort((a, b) => a.displayOrder - b.displayOrder);
        const displayOrderFromApi = this.displayOrderData.map(({ id }) => id);
        this.setState({ displayOrderFromApi });
      }
      if (enableGlobalNavigation) {
        this.headerDisplayItemsNavMenu(this.authHomeStackOrderData);
      }
      this.setState({
        templateDataFetch: true
      });
    });

    Framework.getEventManager().on(constants.USER_PREFERENCES_SUCESS, ((response) => {
      if (response) {
        this.userPreferences = response;
        this.setState({
          isUserPreferenceFetched: true,
          // eslint-disable-next-line react/no-unused-state
          userFirstLogin: CommonUtils.isExistingUser(response.userActivity)
        });
      }
    }));
    Framework.getEventManager().on(constants.USER_PREFERENCES_FAIL, () => {
      this.setState({
        isUserPreferenceFetched: true
      });
    });
    Framework.getEventManager().publish(constants.USER_PREFERENCES_REQUESTED);

    Framework.getEventManager().on(constants.BOOK_COLLECTION_SERVICE_FETCHED, (response) => {
      const { selectedBookIds, isArchived } = response;
      if (isArchived) {
        this.handleArchiveClick(selectedBookIds, true);
      } else {
        this.handleUnarchiveClick(selectedBookIds, true);
      }
    });

    if (expandContainer === 'mondly') {
      this.OnSeeAllMondly();
    }
  }

  componentDidUpdate() {
    this.commonPlugin.updateLangAttribute(); // Update lang atrribute after proper mobx language props update
    const url = window.location.href;
    const redirectTo = url.split('#');

    setTimeout(() => {
      if (redirectTo && redirectTo.length && redirectTo.length === 2 && redirectTo[1] === 'mondly'
        && !this.isLoadedFromMondly && this.mondlyContainer && this.mondlyContainer.current) {
        this.mondlyContainer.current.scrollIntoView();
        this.isLoadedFromMondly = true;
      }
    }, 4000);
  }

  /**
   * Initial api aysnc call fetch on component mount lifecycle
   *
   */
  authHomeInit = () => {
    const {
      bookshelf, productToken, ldUserHash, herobanner
    } = this.props;
    // TODO: This will replaced with other spearate api call
    ldUserHash.fetch();
    // Initialize GTM layer
    CommonUtils.initializeGTM();
    // Initialize CDN product token for AKAMAI
    productToken.fetch(env.PRODUCT_TOKEN);
    // Initialize bookshelf call
    bookshelf.fetch();
    // popular channels fetch
    herobanner.fetchPopularChannels();
    // Framework.getEventManager().on(constants.COURSE_COLLECTION_SERVICE_FAILED, () => {
    //   this.setState({ coursecollectionFetched: true });
    // });
    // Framework.getEventManager().on(constants.COURSE_COLLECTION_SERVICE_FETCHED, (eventData, response) => {
    //   if (eventData?.method === constants.HTTP_REQUEST_METHODS.GET) {
    //     this.usersCollections = response;
    //     this.setState({ coursecollectionFetched: true });
    //   }
    // });
    // Framework.getEventManager().publish(constants.COURSE_COLLECTION_SERVICE_REQUESTED, { method: constants.HTTP_REQUEST_METHODS.GET });
  }

  headerDisplayItemsNavMenu = (orderData) => {
    const dynamicGlobalNavMenu = orderData?.header?.menu;
    if (dynamicGlobalNavMenu?.length > 0) {
      const { bookshelf } = this.props;
      let setheaderMenuNavData = [];
      dynamicGlobalNavMenu.forEach((menuTitle) => {
        switch (menuTitle.id) {
          case 'library':
            if (bookshelf.books.length > 0) {
              setheaderMenuNavData.push(menuTitle);
            }
            break;
          case 'channels':
            if (this.hasShowChannelsCarousel()) {
              setheaderMenuNavData.push(menuTitle);
            }
            break;
          case 'languages':
            if (this.hasShown(constants.LD_COMPONENTS_NAME.MONDLY)) {
              setheaderMenuNavData.push(menuTitle);
            }
            break;
          default:
            setheaderMenuNavData.push(menuTitle);
        }
      });
      setheaderMenuNavData.sort((a, b) => a.displayOrder - b.displayOrder);
      setheaderMenuNavData = setheaderMenuNavData.map((displayItem) => {
        let itemFucntion;
        if (displayItem.id === 'library') {
          itemFucntion = this.onSeeAllFromHeader;
        } else if (displayItem.id === 'channels') {
          itemFucntion = this.OnSeeAllChannelsFromHeader;
        } else if (displayItem.id === 'languages') {
          itemFucntion = this.OnSeeAllMondlyFromHeader;
        } else {
          itemFucntion = () => this.onClickOfDynamicTitleFromHeader(displayItem.action);
        }
        return { ...displayItem, action: itemFucntion };
      });
      this.setState({ headerMenuNavData: setheaderMenuNavData });
    }
  }

  updateLaunchDarklyUser = () => {
    const { ldClient } = this.props;
    const { ldUserHash } = this.props.ldUserHash;
    const user = { id: window.piSession.userId(), lduserHash: ldUserHash };
    try {
      ldClient.identify({
        kind: 'user',
        key: user.id
      }, user.lduserHash, (err, res) => {
        if (res) {
          this.setState({
            LDUserFlagsFetched: true,
            flags: {
              enableOnBoardClose: ldClient.variation('enableOnBoardClose', false),
              enableGlobalNavigation: ldClient.variation('enableGlobalNavigation', 'false'),
              enableBrazeAuthhome: ldClient.variation('enable_Braze_Authhome', false),
              enableArchival: ldClient.variation('enableArchival', false),
              archiveWhenUserHasXOrMoreTitles: ldClient.variation(
                'archiveWhenUserHasXOrMoreTitles', constants.ARCHIVE_WHEN_USER_HAS_X_OR_MORE_TITLES
              ),
              enableChannelLearnRedirection: ldClient.variation(
                'enableChannelLearnRedirection', false
              ),
              enableNativeAppPromoBanners: ldClient.variation('enableNativeAppPromoBanners', false),
              enableHeroBannerVariant2: ldClient.variation('enableHeroBannerVariant2', false),
              enableGoToPage: ldClient.variation('enableGoToPage', false),
              showHEIUserContent: ldClient.variation('showHEIUserContent', false),
              enableQRcode: ldClient.variation('enableQRcode', false),
              enableAuthHomeOnBoard: ldClient.variation('enableAuthHomeOnBoard', false),
              enableMLMAuthHomeRedirection: ldClient.variation('enableMLMAuthHomeRedirection', false),
              enableFreeResponse: ldClient.variation('enableFreeResponse', true),
              enableParagraph: ldClient.variation('enableParagraph', false),
              showAITutor: ldClient.variation('showAITutor', false),
              isAIReviewUser: ldClient.variation('isAIReviewUser', false),
              enableTOCPrintPage: ldClient.variation('enableTOCPrintPage', false),
              enableAITutorOnAuthHome: ldClient.variation('enableAITutorOnAuthHome', false),
              enableMathMLKeyboard: ldClient.variation('enableMathMLKeyboard', false),
              AIEnableMarkdown: ldClient.variation('AIEnableMarkdown', false),
              enableProblemSolveFeature: ldClient.variation('enableProblemSolveFeature', false),
              topicTimeOut: ldClient.variation('topicTimeOut', constants.TOPIC_TIMEOUT),
              enableStreamingAPI: ldClient.variation('enableStreamingAPI', true),
              delayMessageTimerInSecs: ldClient.variation('delayMessageTimerInSecs', constants.DELAY_TIMER_IN_SECS),
              enableAIShowMore: ldClient.variation('enableAIShowMore', false),
              enableAISleepMode: ldClient.variation('enableAISleepMode', true),
              showAISleepModeAB: ldClient.variation('showAISleepModeAB', true),
              enableSummaryContinuation: ldClient.variation('enableSummaryContinuation', false),
              enableExplainQuizOptions: ldClient.variation('enableExplainQuizOptions', 1),
              AIChatPlaceHolderText: ldClient.variation('AIChatPlaceHolderText', ''),
              enableTopicInQuizFlow: ldClient.variation('enableTopicInQuizFlowV1', ''),
              ChannelsVideoScore: ldClient.variation('ChannelsVideoScore', 10),
              showUserHistory: ldClient.variation('showUserHistory', false),
              enableAIShortAnswer: ldClient.variation('enableAIShortAnswer', {}),
              hideChannelsPopout: ldClient.variation('hideChannelsPopout', false),
              showRecommendedPrompts: ldClient.variation('showRecommendedPrompts', false),
              AIBerlinTurnOffSummary: ldClient.variation('AI_Berlin_TurnOff_Summary', []),
              AIBerlinTurnOffPractice: ldClient.variation('AI_Berlin_TurnOff_Practice', [])
            }
          });
        } else {
          console.log('ld error');
          this.setState({
            LDUserFlagsFetched: true
          });
        }
      });
    } catch (err) {
      console.log('ld error');
      this.setState({
        LDUserFlagsFetched: true
      });
    }
  }

  initiateThreatLevel1 = (user, enableAccountAbuseLevel1 = false, enableAccountAbuseLevel2 = false) => {
    const deviceId = window.piSession && window.piSession.getDeviceId();
    if (CommonUtils.isPPlusUser(user) || CommonUtils.isHEIUser()) {
      if (enableAccountAbuseLevel1 || enableAccountAbuseLevel2) {
        Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
          type: 'threatStatus',
          data: { deviceId }
        });
      }
      Framework.getEventManager().on(constants.THREAT_STATUS_SUCCESS, (data) => {
        if (data.threatLevel === 1 && !data.hasUserWarningDisplayed && enableAccountAbuseLevel1) {
          this.setState({
            threatLevel: 1
          });
          Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
            type: 'warningDisplayed'
          });
        } else if (data.threatLevel === 2 && enableAccountAbuseLevel2) {
          this.setState({
            threatLevel: 2
          });
          Framework.getEventManager().on(constants.ACCOUNT_LOCK_SUCCESS, () => {
            CommonUtils.dispatchGaEvent(
              constants.OTP_MODAL,
              constants.LOCK_ACCOUNT_EVENT_NAME,
              constants.LOCK_ACCOUNT_EVENT_ACTION,
              constants.LOCK_ACCOUNT_EVENT_LABEL
            );
          });
          Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
            type: 'accountLock',
            data: {
              deviceId,
              userId: user.id
            }
          });
        }
      });
    }
  }

  handleLogout = () => {
    const { user } = this.props;
    const customFields = {
      is_ghost_account: CommonUtils.isGhostAccount()
    };
    CommonUtils.dispatchGaEvent(constants.ACCOUNT_SETTINGS_CATEGORY, constants.ACCOUNT_SETTINGS, constants.ACCOUNT_SETTINGS_ACTION, constants.ACCOUNT_SETTINGS_SIGNOUT, customFields);
    let redirectionURL = '';
    if (!window.isSSIEnabled && CommonUtils.isPPlusUser(getSnapshot(user))) {
      const XUserId = 'X-UserId';
      const XAuthorization = 'X-Authorization';
      const domain = '.pearson.com';
      document.cookie = `${XUserId}=;path=/;domain=${domain};expires=Thu, 18 Dec 2013 12:00:00 UTC;`;
      document.cookie = `${XAuthorization}=;path=/;domain=${domain};expires=Thu, 18 Dec 2013 12:00:00 UTC;`;

      const piSession = window.piSession;
      const pmcBaseUrl = `${env.BOOKSHELF_CONFIG.pmcSearch}`;
      const logoutUrl = `${pmcBaseUrl}/store/en-us/pearsonplus/logout`;
      const pmcLoginPageUrl = `${pmcBaseUrl}/store/en-us/pearsonplus/login`;
      redirectionURL = `${logoutUrl}?sessionTimedOut=false&consoleHomePageUrl=${pmcLoginPageUrl}`;

      piSession.logout(redirectionURL);
    } else {
      Framework.logout();
    }
  }

  handleConfirmRemoveTitle = () => {
    this.handleRemoveEntitlement(this.state.removeBook);
  }

  handleSortChange = (sortValue, selected) => {
    const eventLabel = (selected === 'A to Z' || selected === 'Z to A')
      ? `Alphabetical (${selected.replace(' to ', '>')})`
      : selected;
    const { SEARCH_EVENT } = constants;
    CommonUtils.dispatchGaEvent(SEARCH_EVENT.EVENT_CATEGORY, SEARCH_EVENT.EVENT_NAME, SEARCH_EVENT.EVENT_ACTION, eventLabel);
  }

  handleInfoTitleClick = (book, bookOrder, isFromL1 = true) => {
    const {
      LIBRARY_EVENT, EVENT_AUTHHOMEEVENT, LOCATION_IN_APP: {
        LIBRARY, LIBRARY_L2
      }
    } = constants;
    const newCustomFields = {
      is_ghost_account: CommonUtils.isGhostAccount(),
      location_in_app: isFromL1 ? LIBRARY : LIBRARY_L2
    };
    CommonUtils.dispatchGaEvent(LIBRARY_EVENT.EVENT_CATEGORY, EVENT_AUTHHOMEEVENT, LIBRARY_EVENT.ETEXT_FEATURE_ELEMENT_CLICK_EVENT_ACTION, LIBRARY_EVENT.INFO_EVENT_LABEL, newCustomFields);
  }

  handleRemoveTitlePopup = (book, bookOrder, isFromInfo = false, locationInApp = constants.LOCATION_IN_APP.LIBRARY) => {
    this.setState({ removeTitleOpen: true, removeBook: book });
    this.handleRemoveGAEvent(true, isFromInfo, locationInApp);
  }

  /**
   * Handles Info remove title events
   * @param {*} isClicked
   * @param {*} locationInApp
   */
  handleRemoveGAEvent = (isClicked, isFromInfo = true, locationInApp = constants.LOCATION_IN_APP.LIBRARY) => {
    const customFields = {
      is_ghost_account: CommonUtils.isGhostAccount(),
      location_in_app: locationInApp
    };
    const { LIBRARY_EVENT, EVENT_AUTHHOMEEVENT } = constants;
    const eventLabel = isFromInfo ? LIBRARY_EVENT.INFO.REMOVE_FROM_LIBRARY : LIBRARY_EVENT.REMOVE_ICON_EVENT_LABEL;
    const eventAction = LIBRARY_EVENT.ETEXT_FEATURE_ELEMENT_CLICK_EVENT_ACTION;
    if (isClicked) {
      CommonUtils.dispatchGaEvent(
        LIBRARY_EVENT.EVENT_CATEGORY,
        EVENT_AUTHHOMEEVENT,
        eventAction,
        eventLabel,
        customFields
      );
    }
  }

  /**
   * Handle add title click
   *
   * @param {*} event
   * @param isClicked
   */
  handleAddTitle = (event, isClicked = true) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const newCustomFields = {
      is_ghost_account: CommonUtils.isGhostAccount(),
      location_in_app: constants.LOCATION_IN_APP.LIBRARY,
      container_value: CommonUtils.getCurrentScreenEventLabel()
    };
    const eventAction = isClicked ? constants.LIBRARY_EVENT.ETEXT_CLICK_EVENT_ACTION : constants.LIBRARY_EVENT.ETEXT_HOVER_EVENT_ACTION;
    // sending GA events for Library
    CommonUtils.dispatchGaEvent(constants.AUTH_HOME_CATEGORY, constants.EVENT_AUTHHOMEEVENT, eventAction, constants.ADD_TITLE_EVENT_LABEL, newCustomFields);
    if (isClicked) {
      window.open(env.ADD_A_TITLE_URL.LIBRARY, '_self');
    }
  }

    /**
   * Handle add title Hover
   *
   * @param {*} event
   */
    handleAddTitleHoverEvent = () => {
      this.handleAddTitle(null, false);
    }

  handleRemoveTitleClose = (event) => {
    if(event){
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({ removeTitleOpen: false, removeBook: null });
  }

  handleRemoveEntitlement = (bookId, isBanner = false) => {
    const payload = {
      data: {
        sourceContext: constants.MOJO,
        subscriptionNumber: bookId.product_entitlements.subscription_id,
        products: [
          {
            productId: bookId.product_id
          }
        ]
      },
      bookId: bookId.product_id,
      isBanner
    };
    Framework.getEventManager().publish(constants.REMOVE_ENTITLEMENT, payload);
    this.handleRemoveTitleClose();
  };

  /**
   * Handels message center click or hover GA events
   *
   * @param {String} id
   * @param {String} ctaId
   * @param {Number} carouselOrder
   * @param {Boolean} isClicked
   */
  handleMessageCenterEvents = (id, carouselOrder, isClicked = false) => {
    const {
      MESSAGE_CENTER_AND_SUBSCRIPTION_BANNER_EVENTS,
      MESSAGE_CENTER_AND_SUBSCRIPTION_BANNER_EVENT_LABEL,
      AUTH_HOME_CATEGORY,
      EVENT_AUTHHOMEEVENT,
      LOCATION_IN_APP
    } = constants;
    const customFields = {
      location_in_app: LOCATION_IN_APP.MESSAGE_CENTER,
      carousel_order: carouselOrder,
      is_ghost_account: CommonUtils.isGhostAccount()
    };
    const eventLabel = MESSAGE_CENTER_AND_SUBSCRIPTION_BANNER_EVENT_LABEL[id];
    if (eventLabel && isClicked) {
      CommonUtils.dispatchGaEvent(
        AUTH_HOME_CATEGORY,
        EVENT_AUTHHOMEEVENT,
        MESSAGE_CENTER_AND_SUBSCRIPTION_BANNER_EVENTS.CTA_CLICK,
        eventLabel,
        customFields
      );
    }
  }

  getCustomFieldsFromBookData = (bookData, book_order, isEventValueRequired = true, sendIsGhostAccount = true) => {
    const containerOrder = this.state.displayOrderFromApi.indexOf('library');
    const { 
      book_id,
      product_id,
      title,
    } = bookData;
    const customFields = {
      book_id: CommonUtils.toString(book_id),
      product_id: CommonUtils.toString(product_id),
      textbook_name: CommonUtils.toString(title),
      book_order: CommonUtils.toString(CommonUtils.toString(book_order))
    };
    if (sendIsGhostAccount) {
      customFields.is_ghost_account = CommonUtils.isGhostAccount();
    }
    if (isEventValueRequired) {
      customFields.event_value = CommonUtils.toString((containerOrder + 1));
    }

    return customFields;
  }


  /**
   * Handle aysnc api call success
   *
  */
  onCombineStatusSuccess = () => {
    const {
      bookshelf, flags, user, eligiblityStatus, language, ldClient, channelRecommendationsByBook, userdetails, herobanner, coursecollection
    } = this.props;
    const isUserFetched = user && user.gpsSubscriptions;
    const books = getSnapshot(bookshelf.books);
    const { bannerType } = getSnapshot(herobanner);
    const isHeroSet = !!bannerType;
    const { enableBrazeAuthhome, enableAuthHomeOnBoard } = this.state.flags;

    // combination of userProfile and BookShelf call success
    if (!this.combineStatusSuccess.userProfileAndBookShelf && isUserFetched && this.state.dataFetched && this.state.LDUserFlagsFetched) {
      if (!bookshelf.isError) {
        bookshelf.setSubscriptionDetails();
      }
      if (CommonUtils.isHEIUser()) {
        Framework.initiateDeviceManagement(user.isImpersonatedId);
      }
      this.initiateThreatLevel1(user, flags.enableAccountAbuseLevel1, flags.enableAccountAbuseLevel2);
      this.combineStatusSuccess.userProfileAndBookShelf = true;
    }

    // if (!this.userProfileAndBookShelfAndPopuplar && this.state.dataFetched && herobanner.popularChannels?.channelCards?.length > 0 && this.state.isUserPreferenceFetched && this.state.coursecollectionFetched && (isUserFetched || books.length === 0) && this.state.templateDataFetch) {
    //   this.coursePlugin.setCourse(null, true);
    //   this.onboardingPlugin.setOnboardSlideData();
    //   this.userProfileAndBookShelfAndPopuplar = true;
    // }

    if (!this.userProfileAndBookShelfAndPopuplar && this.state.dataFetched && herobanner.popularChannels?.channelCards?.length > 0 && this.state.templateDataFetch) {
      this.mlmHeroDashboardPlugin.setHeroDashboard();
      this.userProfileAndBookShelfAndPopuplar = true;
    }

    // combination of languge and LD call success
    if (!this.combineStatusSuccess.langAndLD && language.currentLanguage && this.state.LDUserFlagsFetched) {
      const displayeOrderPayload = {
        locale: language.currentLanguage,
        templateVariant: ldClient.variation('templateVariant', constants.DEFAULT_TEMPLATE)
      };
      Framework.getEventManager().publish(constants.DISPLAY_ORDER_CONTENT_REQUESTED, displayeOrderPayload);
      if (ldClient.variation(constants.LD_COMPONENTS_NAME.TURBO, false)) {
        userdetails.fetchEligibility();
      }
      this.combineStatusSuccess.langAndLD = true;
    }

    // // combination of user preferences and hero set
    // if (!this.combineStatusSuccess.userPrefAndHeroSet
    //   && this.state.isUserPreferenceFetched
    //   && isHeroSet) {
    //   if (!bookshelf.isError) {
    //     const { userActivity } = this.userPreferences;
    //     const isFromEreader = CommonUtils.isQueryParamAvailable('utm_source', constants.APPSFLYERS_EREADER.deep_link_value);
    //     const showOnBoard = userActivity && !CommonUtils.getObjectPropertyValue(userActivity, 'bookWithOnboard', false);
    //     if (enableAuthHomeOnBoard && userActivity && showOnBoard && (!isFromEreader || (isFromEreader && !this.state.userFirstLogin))) {
    //       const { recentBookwithMappedChannel } = this.onboardingPlugin.getRecentBookMappedChannel();
    //       if (recentBookwithMappedChannel) {
    //         this.onboardingPlugin.constructSlideData(recentBookwithMappedChannel);
    //       }
    //     }
    //   }

    //   this.combineStatusSuccess.userPrefAndHeroSet = true;
    // }

    // Combination of userProfile, bookshelf and ChannelByBook
    if (!this.combineStatusSuccess.brazeAnduserProfileAndBookShelf && this.state.brazeInitiated && isUserFetched && this.state.dataFetched && this.state.brazeContentCardFetched) {
      // Set authHome context for braze
      this.brazeTemplatePlugin.setAuthHomeContext();
      try {
        // Show braze content card
        this.brazePlugin.showContentCards();
      } catch (err) {
        console.log('braze error', err);
      }
      this.combineStatusSuccess.brazeAnduserProfileAndBookShelf = true;
    }

    // Combination of userProfile, bookshelf and braze in app
    if (!this.combineStatusSuccess.brazeInAppAnduserProfileAndBookShelf && this.state.brazeInitiated && isUserFetched && this.state.dataFetched && this.state.brazeInAppMsgFetched) {
      // Set authHome context for braze
      this.brazeTemplatePlugin.setAuthHomeContext();
      try {
        // Show braze in app message
        this.brazePlugin.showInAppMesssage();
      } catch (err) {
        console.log('braze error', err);
      }
      this.combineStatusSuccess.brazeInAppAnduserProfileAndBookShelf = true;
    }

    // Combination of braze initiate, user and bookshelf
    if (!this.combineStatusSuccess.brazeInitiatedAnduserProfileAndBookShelf && this.state.brazeInitiated && isUserFetched && this.state.dataFetched && this.state.LDUserFlagsFetched && enableBrazeAuthhome) {
      // Set authHome context for braze
      this.brazeTemplatePlugin.setAuthHomeContext();
      const { BRAZE_TRIGGER_EVENT, BRAZE_CUSTOM_EVENTS: { PPLUS_WEB_AH_LOADED } } = constants;

      BrazeUtils.handleEvent(BRAZE_TRIGGER_EVENT, null, null, {
        name: PPLUS_WEB_AH_LOADED,
        properties: {}
      });
      this.combineStatusSuccess.brazeInitiatedAnduserProfileAndBookShelf = true;
    }

    // Combination of userProfile, bookshelf and ChannelByBook
    if (!this.combineStatusSuccess.userProfileAndBookShelfAndChannelByBook
        && this.state.brazeInitiated
        && isUserFetched
        && this.state.dataFetched
        && this.state.LDUserFlagsFetched
        && enableBrazeAuthhome
        && !!channelRecommendationsByBook.channelData
        && this.state.authHomeExpandContainer === '') {
      const channelData = getSnapshot(channelRecommendationsByBook)?.channelData || null;
      const hasActiveBookStoreSubscription = CommonUtils.hasSubscriptionWithSource(
        user,
        constants.CHANNEL_PARTNER,
        constants.ACTIVE
      );
      const isAllActiveBookStoreHavePPlusEntitlements = CommonUtils.isAllActiveBookStoreHavePPlusEntitlements(
        getSnapshot(user),
        getSnapshot(bookshelf).books
      );
      const hasActiveBookStoreSubscriptionAndNotProvisioned = hasActiveBookStoreSubscription
      && !isAllActiveBookStoreHavePPlusEntitlements;

      if (channelData && !hasActiveBookStoreSubscriptionAndNotProvisioned) {
        const { BRAZE_TRIGGER_EVENT, BRAZE_CUSTOM_EVENTS: { SHOW_MAPPED_CHANNEL_CAMPAIGN } } = constants;
        const { title, url } = channelData;

        BrazeUtils.handleEvent(BRAZE_TRIGGER_EVENT, null, null, {
          name: SHOW_MAPPED_CHANNEL_CAMPAIGN,
          properties: {
            channel_title: title,
            channel_url: url
          }
        });
      }
      this.combineStatusSuccess.userProfileAndBookShelfAndChannelByBook = true;
    }

    // combination of userProfile and LD call success
    if (!this.combineStatusSuccess.userProfileAndLD && isUserFetched && this.state.LDUserFlagsFetched) {
      this.commonPlugin.IntialiseAndIdentifyBraze(() => {
        this.setState({ brazeContentCardFetched: true });
      }, enableBrazeAuthhome);
      this.combineStatusSuccess.userProfileAndLD = true;
    }

    // Initialize Pendo after user details, bookshelf and course tab data are fetched
    if (!this.combineStatusSuccess.userProfileBookShelfAddCourseFetched && isUserFetched && this.state.dataFetched && herobanner.bannerType) {
      this.commonPlugin.InitializePendo();
      const { activeBooksLength, inactiveBooksLength } = CommonUtils.getBooksLength(bookshelf.books);
      const customFields = {
        num_active_titles: activeBooksLength,
        num_inactive_titles: inactiveBooksLength,
        num_containers: coursecollection.courses.length,
        is_ghost_account: CommonUtils.isGhostAccount(),
        event_value: CommonUtils.getCurrentScreenEventLabel(),
        user_entitlement_mix: CommonUtils.getUserEntitlementMix(bookshelf.books),
        channels_sub_status: CommonUtils.channelsSubStatus()
      };
      CommonUtils.dispatchGaEvent(constants.AUTHHOME_APPLICATION, constants.AUTHHOME_APPLICATION_EVENT_NAME, constants.AUTHHOME_APPLICATION_EVENT_ACTION, null, customFields);
      this.combineStatusSuccess.userProfileBookShelfAddCourseFetched = true;
    }

    // initial bookshelf status
    if (!this.combineStatusSuccess.bookShelf && this.state.dataFetched) {
      this.combineStatusSuccess.bookShelf = true;
      if (CommonUtils.isHEIUser()) {
        this.setState({
          showSearch: false
        });
      }
    }

    // bookshelf and LD success
    if (!this.combineStatusSuccess.bookShelfAndLD && this.state.dataFetched && this.state.LDUserFlagsFetched) {
      this.combineStatusSuccess.bookShelfAndLD = true;
      /**
       * Enables archive feature and sets hero banner with channels fetch
       */
      this.commonPlugin.setEnableTitleArchival();
    }

    // piSession sessionID and LD fetch
    if (!this.combineStatusSuccess.piSessionAndLDFetch
        && window.piSession?.getContextId()
        && this.state.LDUserFlagsFetched) {
      this.combineStatusSuccess.piSessionAndLDFetch = true;
      this.nativeAppBannerPlugin.initializeNativeAppBanners();
    }
  }

  /**
   * Redirects to eReader (MLM)
   *
   */
  redirectToEreader = () => {
    const {
      match
    } = this.props;
    const path = CommonUtils.getMLMPath(match);

    if (path) {
      window.location.href = `${env.PLUS_URL}${path}`;
    }
  }

  isReadyToRender = () => {
    const user = Framework.getStoreRegistry().getStore('user');
    let books = Framework.getStoreRegistry().getStore('bookshelf');
    let herobanner = Framework.getStoreRegistry().getStore('herobanner');

    books = getSnapshot(books);
    herobanner = getSnapshot(herobanner);
    this.userDetail = user;
    this.onCombineStatusSuccess();

    if (!this.state.flags.enableMLMAuthHomeRedirection && this.state.LDUserFlagsFetched) {
      this.redirectToEreader();
    }

    if (!this.templateDataFetch && user && user.gpsSubscriptions && this.state.isUserPreferenceFetched && this.state.dataFetched && this.state.LDUserFlagsFetched && this.state.templateDataFetch) {
      this.templateDataFetch = true;
      const {
        userActivity = {},
        survey = []
      } = this.userPreferences;
      const initialSurveyData = {
        sessionId: null,
        showSurveyNextLogin: false,
        isSurveyShown: false,
        user60DayCutoffDate: null,
        currentDate: null,
        type: null
      };
      const updatedUserActivity = {};
      const updatedSurvey = [];
      let hasUpdatedUserActivity = false;
      let hasUpdatedSurvey = false;


      if (typeof this.userPreferences.userActivity?.isFirstLogin === 'undefined') {
        // If user added the first book then updating isFirstLogin in userpreference to false
        updatedUserActivity.isFirstLogin = false;

        this.userPreferences.userActivity = { ...(this.userPreferences?.userActivity || {}) };
        this.userPreferences.userActivity.isFirstLogin = false;
      }
      hasUpdatedUserActivity = !CommonUtils.isEmpty(updatedUserActivity);
      hasUpdatedSurvey = updatedSurvey.length > 0;

      if (hasUpdatedUserActivity || hasUpdatedSurvey) {
        const eventData = {
          eventType: 'PUT',
          payload: {
            ...(hasUpdatedUserActivity
              ? { userActivity: { ...userActivity, ...updatedUserActivity } } : {}),
            ...(hasUpdatedSurvey
              ? { survey: updatedSurvey } : {})
          }
        };

        Framework.getEventManager().publish(constants.USER_PREFERENCES_REQUESTED, eventData);
      }
      this.bannerPlugin.setBookBanner();
      const headerBannerData = this.bannerPlugin.getHeaderBannerData();
      if (headerBannerData) {
        this.setState({
          headerBannerData
        });
      }
    }

    if (!this.onLoadContainer.current && this.state.dataFetched && user.gpsSubscriptions && this.state.LDUserFlagsFetched && this.state.templateDataFetch) {
      this.onLoadContainer.current = true;
      this.initPageTracker();
    }

    return this.state.templateDataFetch;
  }

  /**
   * A method to handle book click
   *
   * @param {Object} bookData
   * @param {String} type
   * @param {String} courseId
   * @param {Object} bookOrder
   * @param {String} chapterId
  */
  // TODO: Revisit and change the redirection logic based on BMC once data is available
  handleBookClick = (bookData, type, courseId = '', bookOrder, chapterId, fromLocation = constants.LOCATION_IN_APP.COURSE_TAB, properties) => {
    const { match, ldClient } = this.props;
    let urlPrefix = match.url;
    // TODO: Use this value for redirection when paper api is ready
    const {
      book_id,
      product_id,
      product_entitlements
    } = bookData;
    const {
      goToPageNo
    } = properties || {};
    const {
      LIBRARY_EVENT, EVENT_AUTHHOMEEVENT, READ, ACTIVITY_TYPE: {
        AUDIO, BOOKMARK, STUDY, SEARCH, NOTES, CONTENTS
      }, LOCATION_IN_APP: {
        COURSE_TAB
      }
    } = constants;
    const {
      username, smsUserId, id
    } = Framework.getStoreRegistry().getStore('user');
    const iconLabel = {
      [AUDIO]: LIBRARY_EVENT.AUDIO_EVENT_LABEL,
      [BOOKMARK]: LIBRARY_EVENT.BOOKMARK_EVENT_LABEL,
      [STUDY]: LIBRARY_EVENT.FLASHCARD_EVENT_LABEL,
      [SEARCH]: LIBRARY_EVENT.SEARCH_EVENT_LABEL,
      [NOTES]: LIBRARY_EVENT.NOTES_EVENT_LABEL,
      [CONTENTS]: LIBRARY_EVENT.CONTENT_EVENT_LABEL
    };
    const bookCustomFields = this.getCustomFieldsFromBookData(bookData, bookOrder, false);
    const isLastAccessed = !!(bookData.last_activity);
    const eventLabel = (isLastAccessed && fromLocation === COURSE_TAB) ? LIBRARY_EVENT.EXEXT_CONTINUE_READING_EVENT_LABEL : LIBRARY_EVENT.ETEXT_OPEN_BOOK_EVENT_LABEL;
    const customFields = {
      is_ghost_account: CommonUtils.isGhostAccount(),
      location_in_app: fromLocation
    };
    ldClient.track(constants.LD_EVENT_NAME.LIBRARY_SECTION_CLICK);
    ldClient.track(constants.LD_EVENT_NAME.TITLE_CARD_CLICK);
    ldClient.flush();
    if (type !== READ && iconLabel[type]) {
      CommonUtils.dispatchGaEvent(LIBRARY_EVENT.EVENT_CATEGORY, EVENT_AUTHHOMEEVENT, LIBRARY_EVENT.ETEXT_FEATURE_ELEMENT_CLICK_EVENT_ACTION, iconLabel[type], customFields);
    }
    if (goToPageNo) {
      CommonUtils.dispatchGaEvent(LIBRARY_EVENT.EVENT_CATEGORY, EVENT_AUTHHOMEEVENT, LIBRARY_EVENT.ETEXT_FEATURE_ELEMENT_CLICK_EVENT_ACTION, LIBRARY_EVENT.GOTOPAGE_EVENT_LABEL, customFields);
    }
    CommonUtils.dispatchGaEvent(LIBRARY_EVENT.EVENT_CATEGORY, EVENT_AUTHHOMEEVENT, LIBRARY_EVENT.EVENT_ACTION_ETEXT, LIBRARY_EVENT.EVENT_LABEL_ETEXT, bookCustomFields);
    // open book event
    CommonUtils.dispatchGaEvent(LIBRARY_EVENT.EVENT_CATEGORY, EVENT_AUTHHOMEEVENT, LIBRARY_EVENT.ETEXT_CLICK_EVENT_ACTION, eventLabel, { ...customFields, container_value: CommonUtils.getCurrentScreenEventLabel() });
    this.heroDashboardPlugin.handleCourseTabEvents(fromLocation);
    const getContextPath = courseId && courseId !== '' ? `&context_id=${courseId}` : '';
    const userPreferredType = `userPreferredType=${type}`;
    const selectedChapterId = (chapterId) ? `&${constants.CHAPTER_ID_QUERY_PARAM}=${chapterId}` : '';
    const currentGoToPageNo = (goToPageNo) ? `&${constants.GO_TO_PAGENO_QUERY_PARAM}=${goToPageNo}` : '';
    let sourceFromValue = constants.HOME2;
    const mlmBookData = CommonUtils.getMLMRouteBookData(match) || {};
    const isValidMLMRoute = mlmBookData?.bookId || mlmBookData?.courseId;
    const isHeroBookAndValidMlmRoute = (isValidMLMRoute) && fromLocation === constants.LOCATION_IN_APP.COURSE_TAB;
    const canadianProxy = this.canadianProxy === '1' ? '&cp=Y' : '';
    let productId = product_id;
    let isIntegratedLaunch = false;
    const integratedBMC = [constants.INTEGRATED_SMS, constants.INTEGRATED_RUMBA, constants.CG_RENEWALS];
    const businessModelList = [constants.DIRECT_TO_LEARNER, constants.DIRECT_TO_LEARNER_RENEWAL, constants.COURSE_WARE, constants.DIRECT_TO_CONSUMER];
    const businessModelCode = product_entitlements.business_model_code;
    const isTpi = CommonUtils.isGhostAccount() ? '&isTpi=Y' : '';

    if (integratedBMC.includes(businessModelCode)) {
      productId = book_id;
      isIntegratedLaunch = true;
    }

    if (urlPrefix.indexOf('/home') > -1) {
      urlPrefix = match.url.split('/home')[0];
    }

    if (isHeroBookAndValidMlmRoute) {
      try {
        // eslint-disable-next-line no-undef
        sourceFromValue = btoa(`${env.CONTENT_HOST}${match.url}?${constants.EREADER_UTM_SOURCE}${canadianProxy}${isTpi}`);
      } catch (err) {
        // eslint-disable-next-line no-undef
        sourceFromValue = btoa(`${env.CONTENT_HOST}/home2`);
      }
    } else {
      const route = courseId ? `/courses/${courseId}/products/${productId}` : `/products/${productId}`;
      // eslint-disable-next-line no-undef
      const encodedRoute = btoa(route);

      try {
        // eslint-disable-next-line no-undef
        sourceFromValue = btoa(`${env.CONTENT_HOST}/home2/${encodedRoute}?${constants.EREADER_UTM_SOURCE}${canadianProxy}${isTpi}`);
      } catch (err) {
        // eslint-disable-next-line no-undef
        sourceFromValue = btoa(`${env.CONTENT_HOST}/home2`);
      }
    }
    const sourceFrom = `&${constants.SOURCE_FROM}=${sourceFromValue}`; // MLM home2 router identification

    if (isHeroBookAndValidMlmRoute || isIntegratedLaunch) {
      if (courseId) {
        window.location.href = `${urlPrefix}/courses/${courseId}/products/${productId}?${userPreferredType}${canadianProxy}${currentGoToPageNo}${sourceFrom}`;
      } else {
        window.location.href = `${urlPrefix}/products/${productId}?${userPreferredType}${canadianProxy}${currentGoToPageNo}${sourceFrom}`;
      }
    } else if (businessModelList.includes(businessModelCode) && !window.location.host.includes(constants.ETEXT_PERF)) {
      if (courseId) {
        window.location.href = `${urlPrefix}/courses/${courseId}?${userPreferredType}${selectedChapterId}${currentGoToPageNo}${sourceFrom}`;
      } else {
        window.location.href = `${urlPrefix}/products/${productId}?${userPreferredType}${selectedChapterId}${currentGoToPageNo}${sourceFrom}`;
      }
    } else if (courseId) {
      window.location.href = `${urlPrefix}/courses/${courseId}?${userPreferredType}${selectedChapterId}${currentGoToPageNo}${sourceFrom}`;
    } else {
      window.location.href = `${urlPrefix}/products/${productId}?${userPreferredType}${selectedChapterId}${currentGoToPageNo}${sourceFrom}`;
    }
  }

  /**
   * handles Reactivate CTA button click
   * @param {*} book
   * @param {*} bookOrder
   */
  handleReactiveCTAClick = (book, locationInApp = constants.LOCATION_IN_APP.COURSE_TAB) => {
    const {
      LIBRARY_EVENT: {
        EVENT_CATEGORY,
        ETEXT_CLICK_EVENT_ACTION,
        ETEXT_PAUSED_REACTIVATE_EVENT_LABEL,
        ETEXT_EXPIRED_REACTIVATE_EVENT_LABEL
      },
      EVENT_AUTHHOMEEVENT
    } = constants;
    const {
      gpsSubscriptionOfBook,
      bannerData
    } = book;
    const customFields = {
      is_ghost_account: CommonUtils.isGhostAccount(),
      location_in_app: locationInApp,
      container_value: CommonUtils.getCurrentScreenEventLabel()
    };
    const { subscription_id: subscriptionId } = CommonUtils.getProductEntitlementOfBook(book);
    if (gpsSubscriptionOfBook?.status === constants.PAUSED && subscriptionId) {
      const url = `${env.BOOKSHELF_CONFIG.updatePayment}?subscriptionNumber=${subscriptionId}`;
      window.open(url, '_blank');
      CommonUtils.dispatchGaEvent(EVENT_CATEGORY, EVENT_AUTHHOMEEVENT, ETEXT_CLICK_EVENT_ACTION, ETEXT_PAUSED_REACTIVATE_EVENT_LABEL, customFields);
    }
    // Checking expired pplus book here and redirection to PDP page for renew
    if (gpsSubscriptionOfBook?.status === constants.EXPIRED && subscriptionId && bannerData) {
      const url = bannerData?.action[0]?.launchURL;
      if (url) {
        window.open(url, bannerData?.action[0]?.target || '_blank');
      }
      CommonUtils.dispatchGaEvent(EVENT_CATEGORY, EVENT_AUTHHOMEEVENT, ETEXT_CLICK_EVENT_ACTION, ETEXT_EXPIRED_REACTIVATE_EVENT_LABEL, customFields);
    }
  }

  /**
   * handles library banner's dismiss call back
   *
   * @param {*} event
   * @param {*} bookData
   * @param {*} isIgnoreBtnClick
   * @param {*} ctaHasIgnoreAction
   * @param {*} launchURL
   * @param {*} target
   * @param {*} id
   */

  handleSubscriptionBannerClick
    = (event, bookData, isDismissed, launchURL, target = '_blank', bannerId, locationInApp = constants.LOCATION_IN_APP.COURSE_TAB) => {
      const {
        book_id,
        product_id
      } = bookData;

      if (isDismissed) {
        const { bookshelf, user } = this.props;
        const bookShelfData = getSnapshot(bookshelf);
        const userDetails = getSnapshot(user);
        const { subscription_id: subscriptionIdOfBook } = CommonUtils.getProductEntitlementOfBook(bookData);
        let endDate = null;
        let subscriptionId = null;
        const userGpsSubscriptions = CommonUtils.getMatchingGpsSubscriptionOfBook(subscriptionIdOfBook, userDetails);
        if (userGpsSubscriptions) {
          endDate = CommonUtils.getBannerExpirationDate(userGpsSubscriptions, bannerId);
          subscriptionId = userGpsSubscriptions.subscriptionId;
        }
        /**
         * subscriptionId, endDate, bannerId are mandatory for user preference PUT call and
         * either book_id or product_id is mandatory
         */
        const hasPayloadData = ((book_id || product_id) && subscriptionId && endDate && bannerId);

        /**
         * Updating bookshelf store's showSecondaryTitle property if the user has clicked on close icon
         * or clicked on a CTA which has close icon action
         */
        if (bookShelfData.books.length > 0) {
          const updatedBooks = bookShelfData.books.map((book) => {
            // eslint-disable-next-line camelcase
            if (book.book_id === book_id && book.product_id === product_id) {
              const currentBookBannerData = book.bannerData;
              const updatedBook = { ...book, bannerData: { ...currentBookBannerData, isDismissed: true } };

              return updatedBook;
            }

            return book;
          });
          const updatedbookshelf = { ...bookShelfData, books: updatedBooks };
          Framework.getStoreRegistry().getStore('bookshelf').set(updatedbookshelf);
        }

        /**
         * Making PUT call to userpreference API if user has dismissed the banner
         */
        if (hasPayloadData) {
          const eventData = {
            eventType: 'PUT',
            payload: {
              dismissedAlertPreferences: [{
                subscriptionId,
                type: bannerId,
                bookId: book_id,
                productId: product_id,
                expireAt: endDate
              }]
            }
          };
          Framework.getEventManager().publish(constants.USER_PREFERENCES_REQUESTED, eventData);
        }
      } else {
        this.bannerPlugin.handleSubscriptionBannerNewGAEvent(bannerId, locationInApp, true);
      }

      if (launchURL) {
        window.open(launchURL, target);
      }
    }

  /**
   * Handles info notificaion cta hover events
   */
  handleNotificationCTAHover = (bannerId, isDismissed, locationInApp = constants.LOCATION_IN_APP.LIBRARY) => {
    if (!isDismissed) {
      this.bannerPlugin.handleSubscriptionBannerNewGAEvent(bannerId, locationInApp);
    }
  }


  pushTelemetry = (user) => {
    const loggedUser = user;
    CommonUtils.logUserTelemetryEvent('', loggedUser.id);
    CommonUtils.sendPageViewEvent();
    CommonUtils.initTelemetryPageAction();
  }

  onBackClick = () => {
    this.setState({
      authHomeExpandContainer: '',
      actitveNavHeaderTitle: '',
      seeAllView: false,
      isSearched: false,
      showSearch: !CommonUtils.isHEIUser() && !this.isGhostAccount,
      onClickFromHeaderNavMenu: false,
      searchKeyWord: '',
      activeHeaderL2Tab: ''
    }, () => {
      // TODO: Is initPageTracker required here
      this.initPageTracker();
    });
  }

  moveTop = () => {
    if (window.navigator.userAgent.toLowerCase().match(/firefox|fxios/i)) {
      // behaviour smoot is works on firefox after the component gets loaded
      setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      },
      50);
    } else {
      window.scroll({
        top: 0,
        left: 0
      });
    }
  }

  OnSeeAllProcourse = () => {
    this.setState({
      authHomeExpandContainer: 'ProcourseExpanded',
      seeAllView: true,
      showSearch: false
    }, () => {
      this.moveTop();
    });
  }

  OnSeeAllMondly = () => {
    this.setState({
      actitveNavHeaderTitle: constants.GLOBAL_NAV_MENU_BAR.EVENT_LABEL.LANGUAGES,
      seeAllView: false,
      showSearch: !CommonUtils.isHEIUser() && !this.isGhostAccount,
      isSearched: false,
      l2ModalOpen: true
    }, () => {
      this.moveTop();
    });
  }

  onSeeAll = (isFromLibrary = true) => {
    this.setState({
      authHomeExpandContainer: 'Library',
      seeAllView: true,
      actitveNavHeaderTitle: constants.GLOBAL_NAV_MENU_BAR.EVENT_LABEL.LIBRARY,
      activeLibraryL2Tab: constants.LIBRARYL2_TABS.MY_BOOKS
    }, () => {
      this.moveTop();
      this.initPageTracker();
    });
    if (isFromLibrary) {
      const { ldClient } = this.props;
      ldClient.track(constants.LD_EVENT_NAME.LIBRARY_SECTION_CLICK);
    }
  }

  OnSeeAllChannelsFromHeader = () => {
    this.setState({
      authHomeExpandContainer: 'ChannelsExpanded',
      seeAllView: true,
      showSearch: false,
      onClickFromHeaderNavMenu: true
    }, () => {
      this.moveTop();
    });
  }

  OnSeeAllMondlyFromHeader = () => {
    this.setState({
      authHomeExpandContainer: 'MondlyExpanded',
      seeAllView: true,
      showSearch: false,
      onClickFromHeaderNavMenu: true
    }, () => {
      this.moveTop();
    });
  }

  onSeeAllFromHeader = () => {
    this.setState({
      authHomeExpandContainer: 'Library',
      seeAllView: true,
      showSearch: !CommonUtils.isHEIUser() && !this.isGhostAccount
    }, () => {
      this.moveTop();
    });
  }

  onPearsonLogoClick = () => {
    const {
      HEADER_EVENT,
      EVENT_USER_INTERACTION,
      AUTHHOME_APPLICATION,
      LOCATION_IN_APP
    } = constants;
    const customFields = {
      location_in_app: LOCATION_IN_APP.AUTHHOME,
      is_ghost_account: CommonUtils.isGhostAccount()
    };
    const { enableGlobalNavigation } = this.state.flags;

    if (enableGlobalNavigation) {
      this.setState({
        authHomeExpandContainer: '',
        actitveNavHeaderTitle: '',
        seeAllView: false,
        isSearched: false,
        showSearch: !CommonUtils.isHEIUser() && !this.isGhostAccount,
        onClickFromHeaderNavMenu: false
      }, () => {
        // TODO: Is initPageTracker required here
        this.initPageTracker();
      });
    } else {
      const launchURL = CommonUtils.isPPlusUser(this.userDetail) ? env.PLUS_URL : env.BOOKSHELF_CONFIG.logo;

      // disabling click on Pearson Plus logo for HEI users
      if (!CommonUtils.isHEIUser()) window.open(launchURL, '_self');
    }

    CommonUtils.dispatchGaEvent(constants.AUTH_HOME_CATEGORY, constants.EVENT_AUTHHOMEEVENT, constants.LOGO_EVENT_ACTION, '', customFields);
    CommonUtils.dispatchGaEvent(AUTHHOME_APPLICATION, EVENT_USER_INTERACTION, HEADER_EVENT.HEADER_CLICK_EVENT_ACTION, HEADER_EVENT.LOGO_EVENT_LABEL, null);
  }

  onClickOfDynamicTitleFromHeader = (actionObj) => {
    window.open(actionObj.url, '_self');
  }

  hasShowChannelsCarousel = () => {
    const {
      flags
    } = this.props;
    const isStudent = CommonUtils.getUserRole();
    const LD_CHECK = ((isStudent && flags.enableStudyChannelCarousel) || (!isStudent && flags.enableStudyChannelEducator));
    return LD_CHECK;
  };

  hasShown = (flagName) => {
    const {
      flags,
      userdetails,
      ldClient
    } = this.props;

    return CommonUtils.hasShown(flagName, flags, userdetails, ldClient);
  };

  handleFilterBooks = (books, searchtext) => {
    const hasSearchAvailable = searchtext && searchtext.length > 0;
    const seeAll = this.state.seeAllView;

    /* if ther user use eTextSearch, then we need to select Etextbook tab */
    if(searchtext.length == 0 && !seeAll) {
      this.setState({activeHeaderL2Tab: ''});
    } else if(this.state.activeHeaderL2Tab === constants.MORE_AT_PEARSON || this.state.activeHeaderL2Tab == '') {
      this.setState({activeHeaderL2Tab: constants.MY_ETEXTBOOK});
    }

    this.setState({
      authHomeExpandContainer: (hasSearchAvailable || seeAll) ? 'Library' : '',
      isSearched: !!hasSearchAvailable,
      booksData: hasSearchAvailable ? books : [],
      searchKeyWord: searchtext
    });
  }

  handleAccountAbuseClose = () => {
    this.setState({
      threatLevel: 0
    });
  }

  handleAccountLockClose = () => {
    CommonUtils.dispatchGaEvent(constants.TL2_CATEGORY, constants.TL2_EVENT_NAME, constants.TL2_EVENT_ACTION, constants.TL2_EVENT_LABEL);
    Framework.logout();
  }

  handleSearchEvent = (eventCategory, event, action, label, customFields = null, redirectURL = null) => {
    CommonUtils.dispatchGaEvent(eventCategory, event, action, label, customFields);
    if (redirectURL !== null) {
      window.open(redirectURL, '_self');
    }
  }

  /**
   * Is expired books in L2 page and no books in L1 page
   * This for LibraryShelf
   *
   * @returns
   */
  showViewAllTitleInMC = () => {
    const { bookshelf } = this.props;
    const books = getSnapshot(bookshelf).books;
    const hasInactiveBooks = CommonUtils.hasInActiveBooks(books);
    const hasArchivedBooks = CommonUtils.hasArchivedBooks(books);

    return hasInactiveBooks || hasArchivedBooks;
  }

  /**
   * Returns [] if there are no books to be shown in library
   * If books length lesser than 4 remaining cards will be filled with Add title
   * else returns first 4 books
   *
   * @returns
   */
  getLibraryShelfCards = (LIBRARY_CARDS_LIMIT) => {
    const bookshelf = Framework.getStoreRegistry().getStore('bookshelf');
    const bookShelfBooks = getSnapshot(bookshelf.books);
    const books = this.heroBannerPlugin.filterLibraryL1Books(bookShelfBooks, true);
    const libraryCards = [];

    // TODO: Need to refactor this code
    if (books.length > 0 && books.length < LIBRARY_CARDS_LIMIT) {
      libraryCards.push(...books);
      libraryCards.push(...Array(LIBRARY_CARDS_LIMIT - libraryCards.length).fill({ addTitle: true }));

      return libraryCards;
    }

    return books.length >= LIBRARY_CARDS_LIMIT ? books.slice(0, LIBRARY_CARDS_LIMIT) : [];
  }

  /**
   * Handles marketing card primary CTA click/hover and instrumentation
   *
   * @param {*} isClicked
   */

  handleMarketingCardPrimaryCTA = (isClicked = false) => {
    const { LIBRARY_EVENT, ETEXT_CONTENT_CARD } = constants;
    const eventAction = isClicked ? LIBRARY_EVENT.ETEXT_CLICK_EVENT_ACTION : LIBRARY_EVENT.ETEXT_HOVER_EVENT_ACTION;
    const customFields = {
      is_ghost_account: CommonUtils.isGhostAccount(),
      location_in_app: constants.LOCATION_IN_APP.LIBRARY,
      container_value: CommonUtils.getCurrentScreenEventLabel()
    };
    CommonUtils.dispatchGaEvent(
      constants.AUTH_HOME_CATEGORY,
      constants.EVENT_AUTHHOMEEVENT,
      eventAction,
      ETEXT_CONTENT_CARD,
      customFields
    );
    if (isClicked) {
      window.open(env.ADD_A_TITLE_URL.LIBRARY);
    }
  }


  /**
   * Handles marketing card Secondary CTA click
   * @param {*} isClicked
   */

  handleMarketingCardSecondaryCTA = () => {
    this.onSeeAll(false);
  }

  scrollToChannelContainer = () => {
    if (this.channelsContainer && this.channelsContainer.current) {
      this.channelsContainer.current.scrollIntoView({
        block: 'center'
      });
    }
  }

  renderBasedOnOrder = () => {
    const {
      user,
      language,
      ldClient,
      bookshelf,
      herobanner
    } = this.props;
    const renderData = new Map();
    const mainContainer = [];
    const channelsCustomTitles = ldClient.variation('channelsCustomTitles', 'Channels');
    const renderDataorder = this.state.displayOrderFromApi.length > 0 ? this.state.displayOrderFromApi : constants.DEFAULT_RENDER_ORDER;

    renderDataorder.forEach((templateId, key) => {
      const containerOrder = key + 1;
      const { AUTHHOME_HERO_BANNER } = constants.BRAZE_TARGET_AREA;
      const heroContentCard = this.brazePlugin.getContentCardByTargetArea(AUTHHOME_HERO_BANNER);

      if (templateId === 'heroView' && this.state.activeHeaderL2Tab !== constants.MORE_AT_PEARSON) {
        renderData.set(templateId,
          <Fragment>
            <Container
              enableGutters
              enableTopAndBottomPadding
            >
              {this.heroDashboardPlugin.renderHeroDashboard()}
            </Container>
            {heroContentCard
              && (
                <Container
                  enableGutters
                >
                  <BrazeContainer
                    data={heroContentCard?.data}
                    callbackForBraze={heroContentCard?.callbackForBraze}
                  />
                </Container>
              )
            }
          </Fragment>);
      }
      if (templateId === 'mondly' && this.hasShown(constants.LD_COMPONENTS_NAME.MONDLY) && this.state.activeHeaderL2Tab === constants.MORE_AT_PEARSON) {
        renderData.set(templateId,
          <Container
            ref={this.mondlyContainer}
            className="mondly-container"
            data-title="Languages"
            data-order={containerOrder}
            enableTopAndBottomPadding={false}
            enableGutters
          >
            <Suspense fallback={<ChannelsLoader />}>
              <MondlyLanguageContainer
                containerOrder={containerOrder}
                userDetail={this.userDetail}
                env={env.ENVIRONMENT}
                locale={language.currentLanguage}
                onSeeAllClick={this.OnSeeAllMondly}
                title={this.getContainerData('mondly', 'title')}
              />
            </Suspense>
          </Container>);
      }
      if (templateId === 'turbo' && this.hasShown(constants.LD_COMPONENTS_NAME.TURBO) && this.state.activeHeaderL2Tab === constants.MORE_AT_PEARSON) {
        renderData.set(templateId,
          <Container
            className="turbo-container"
            data-title="Turbo"
            data-order={containerOrder}
            enableTopAndBottomPadding={false}
            enableGutters
          >
            <Suspense fallback={<ChannelsLoader />}>
              <TurboContainer
                containerOrder={containerOrder}
                userDetail={this.userDetail}
                env={env.ENVIRONMENT}
                locale={language.currentLanguage}
                title={this.getContainerData('turbo', 'title')}
              />
            </Suspense>
          </Container>);
      }
      if (templateId === 'procourse' && this.hasShown(constants.LD_COMPONENTS_NAME.ITPRO) && this.state.activeHeaderL2Tab === constants.MORE_AT_PEARSON) {
        renderData.set(templateId,
          <Container
            className="procourse-container"
            data-title="ITpro"
            data-order={containerOrder}
            enableTopAndBottomPadding={false}
            enableGutters
          >
            <Suspense fallback={<ChannelsLoader />}>
              <ChannelCarouselContainer
                containerOrder={containerOrder}
                customTitle={channelsCustomTitles}
                itPro
                userDetail={this.userDetail}
                userType={CommonUtils.getUserRole(getSnapshot(bookshelf)?.books) ? 'general' : constants.EDUCATOR}
                env={env.ENVIRONMENT}
                locale={language.currentLanguage}
                onSeeAllClick={this.OnSeeAllProcourse}
              />
            </Suspense>
          </Container>);
      }
    });
    if (renderData.size > 0) {
      const containerRenderingOrder = [];
      renderData.forEach((componentToRender, templateId) => {
        const templateContainer = this.displayOrderData.find(({ id }) => (id === templateId));
        if (templateContainer) {
          const positionIndex = containerRenderingOrder.findIndex(({ displayOrder }) => (displayOrder === templateContainer.displayOrder));
          if (positionIndex === -1) {
            containerRenderingOrder.push({ displayOrder: templateContainer.displayOrder, render: [componentToRender] });
          } else {
            containerRenderingOrder[positionIndex].render.push(componentToRender);
          }
        }
      });
      /**
       * Structure for containerRenderingOrder
       * [
       *  {
       *    displayOrder: <NUMBER>,
       *    render: <LIST>[]
       *  },
       * ... ]
       */
      const authHomeContainers = containerRenderingOrder.map(containers => (
        // If there are two components to be added in the same position then group them under the same container
        containers.render.length === 1
          ? containers.render[0]
          : (
            <Container
              className="flex-container"
              enableGutters
              enableTopAndBottomPadding
            >
              {containers.render.map(render => render)}
            </Container>
          )
      ));
      mainContainer.push(<div className="maincontainer">{authHomeContainers}</div>);
    }

    return [...mainContainer];
  }

  handleMenuClick = (e, eventLabel) => {
    const customFields = {
      is_ghost_account: CommonUtils.isGhostAccount()
    };

    CommonUtils.dispatchGaEvent(constants.ACCOUNT_SETTINGS_CATEGORY, constants.ACCOUNT_SETTINGS, constants.ACCOUNT_SETTINGS_ACTION, eventLabel, customFields);
  }

  initPageTracker = () => {
    const { flags } = this.state;
    CommonUtils.pageContainerTracker(flags);
  }

  /**
   * Function to retrieve value for the container name and property name provided
   * @param {*} containerName 
   * @param {*} propertyName 
   * @returns 
   */
  getContainerData = (containerName, propertyName) => {
    const templateData = this.authHomeStackOrderData[containerName];
    const containerInfo = templateData || constants.DEFAULT_CONTAINER_DATA[containerName];
    let value;

    if (containerInfo){
      if (propertyName.indexOf('.') !== -1) {
        const propertyNameList = propertyName.split('.');
        value = propertyNameList.reduce((previousValue, currentValue) => {
          let result;

          if (typeof previousValue !== 'undefined' && previousValue.hasOwnProperty(currentValue)) {
            result = previousValue[currentValue];
          }

          return result;
        }, containerInfo);
      } else {
        if(containerInfo.hasOwnProperty(propertyName)){
          value = containerInfo[propertyName];
        }
      }
    }

    return value;
  }

  /**
   * Switches Hero CTA from primary to secondary if there is a code redeemed braze banner displayed
   *
   * @param {*} headerContentCard
   */
  changeHeroCTAIfCRBanner = (headerContentCard, isCardDismissed = false) => {
    if (headerContentCard
      && headerContentCard.data
      && (headerContentCard.data.template === constants.TEMPLATE_E
        || headerContentCard.data.id === constants.BANNERS.CODE_REDEEMED.id)
      && headerContentCard.data.action
      && headerContentCard.data.action.length > 0) {
      const { herobanner } = this.props;
      const { showCTAAsSecondary } = getSnapshot(herobanner)?.config;

      if (!showCTAAsSecondary && !isCardDismissed) {
        herobanner.setConfig({ showCTAAsSecondary: true });
      } else if (showCTAAsSecondary && isCardDismissed) {
        herobanner.setConfig({ showCTAAsSecondary: false });
      }
    }
  };

  /**
   * Returns Library L2 tabs
   *
   * @returns
   */
  getLibraryL2Tabs = () => {
    const { language, bookshelf } = this.props;
    const books = getSnapshot(bookshelf)?.books;
    const { archivedBooks, unArchivedBooks } = CommonUtils.getArchivedOrUnArchivedBooks(books);

    return [{
      name: language.getMessage('libraryL2.myBooks'),
      count: `(${unArchivedBooks.length})`,
      id: constants.LIBRARYL2_TABS.MY_BOOKS
    },
    {
      name: language.getMessage('libraryL2.archived'),
      count: `(${archivedBooks.length})`,
      id: constants.LIBRARYL2_TABS.ARCHIVED
    }];
  }

  /**
   * Returns Library L2 books based on the active library tab
   *
   * @param {*} books
   * @returns
   */
  getLibraryL2Books = (books) => {
    const { ARCHIVED } = constants.LIBRARYL2_TABS;
    const { archivedBooks, unArchivedBooks } = CommonUtils.getArchivedOrUnArchivedBooks(books);
    const { activeLibraryL2Tab } = this.state;
    const libraryL2Books = activeLibraryL2Tab.toLowerCase() === ARCHIVED.toLowerCase()
      ? archivedBooks : unArchivedBooks;

    return libraryL2Books;
  }

  /**
   * Sets selected library L2 tab
   *
   * @param {*} selectedTab
   */
  handleLibraryL2TabClick = (selectedTab) => {
    this.setState({ activeLibraryL2Tab: selectedTab });
  }

  /**
   * Constructs payload for archive
   * Removes hero book id from payload when there is only one book with bookid
   *
   * @param {*} selectedBooks
   * @returns
   */
  constructArchivePayload = (selectedBooks = []) => {
    const { BOOKSHELF_USER_COLLECTIONS } = constants;

    const payload = selectedBooks.filter(({
      product_entitlements,
      book_id
    }) => (
      product_entitlements?.business_model_code
      && book_id)).map(({
      product_entitlements,
      book_id
    }) => ({
      bookId: book_id,
      bmc: product_entitlements.business_model_code,
      type: BOOKSHELF_USER_COLLECTIONS.ARCHIVED
    }));

    return payload;
  }

  /**
   * Handles archive or unarchive events
   *
   * @param {*} locationInApp
   * @param {*} selectedBooksCount
   * @param {*} isArchived
   */
  handleArchiveOrUnarchiveEvents = (locationInApp, selectedBooksCount, isArchived = false) => {
    const {
      CLICK_ARCHIVE_CTA,
      CLICK_UNARCHIVE_CTA,
      ARCHIVE,
      UNARCHIVE
    } = constants.ARCHIVE_EVENTS;
    const { AUTHHOME_APPLICATION, EVENT_USER_INTERACTION } = constants;
    const eventAction = isArchived ? CLICK_ARCHIVE_CTA : CLICK_UNARCHIVE_CTA;
    const eventLabel = isArchived ? ARCHIVE : UNARCHIVE;
    const eventValue = selectedBooksCount;
    const customFields = {
      event_value: eventValue,
      location_in_app: locationInApp,
      is_ghost_account: CommonUtils.isGhostAccount()
    };

    CommonUtils.dispatchGaEvent(
      AUTHHOME_APPLICATION,
      EVENT_USER_INTERACTION,
      eventAction,
      eventLabel,
      customFields
    );
  }

  /**
   * Handles archive click
   *
   * @param {*} selectedBooks
   * @param {*} isPostCallSuccess
   * @param {*} location
   */
  handleArchiveClick = (selectedBooks = [], isPostCallSuccess = false, location) => {
    const { HTTP_REQUEST_METHODS } = constants;

    if (selectedBooks.length > 0 && !isPostCallSuccess) {
      this.handleArchiveOrUnarchiveEvents(location, selectedBooks.length, true);
      const payload = this.constructArchivePayload(selectedBooks);
      const eventData = {
        method: HTTP_REQUEST_METHODS.POST,
        payload
      };

      Framework.getEventManager().publish(constants.BOOK_COLLECTION_SERVICE_REQUESTED, eventData);
    }

    if (isPostCallSuccess) {
      const { bookshelf } = this.props;
      bookshelf.archiveOrUnarchiveSelectedBooks(selectedBooks, true);
      this.updateSearchedBooks(selectedBooks, true);
      this.coursePlugin.setCourse();
    }
  }

  /**
   * Handles unarchive click
   *
   * @param {*} selectedBooks
   * @param {*} isDeleteCallSuccess
   */
  handleUnarchiveClick = (selectedBooks = [], isDeleteCallSuccess = false, location) => {
    const { HTTP_REQUEST_METHODS } = constants;

    if (selectedBooks.length > 0 && !isDeleteCallSuccess) {
      this.handleArchiveOrUnarchiveEvents(location, selectedBooks.length, false);
      const payload = this.constructArchivePayload(selectedBooks);
      const eventData = {
        method: HTTP_REQUEST_METHODS.DELETE,
        payload
      };

      Framework.getEventManager().publish(constants.BOOK_COLLECTION_SERVICE_REQUESTED, eventData);
    }

    if (isDeleteCallSuccess) {
      const { bookshelf } = this.props;
      bookshelf.archiveOrUnarchiveSelectedBooks(selectedBooks, false);
      this.updateSearchedBooks(selectedBooks, false);
      this.coursePlugin.setCourse();
    }
  }


  /**
   * Triggers on LibraryExpand V1 init and whenever active tab is changed
   *
   */
  handleLibraryL2init = () => {
    CommonUtils.appDomElementIntersectionTracker();
  }

  /**
   * Archives or unarchives selected searched books
   *
   * @param {*} selectedBookIds
   * @param {*} isArchived
   */
  updateSearchedBooks(selectedBookIds = [], isArchived = false) {
    const { booksData, isSearched } = this.state;

    if (isSearched) {
      const updatedBooks = booksData.map((book) => {
        if (selectedBookIds.includes(book.book_id) && book.isArchived !== isArchived) {
          return {
            ...book,
            isArchived
          };
        }

        return { ...book };
      });

      if (updatedBooks.length > 0) {
        this.setState({
          booksData: updatedBooks
        });
      }
    }
  }

  /**
   * Callback function to open the modal
   */
  handleQrCodeClick = () => {
    const { AUTHHOME_APPLICATION, EVENT_USER_INTERACTION, HEADER_EVENT, QR_CODE_EVENT } = constants;
    this.setState(state => ({
      modalProps: {
        ...state.modalProps,
        ...CommonUtils.getModalProps(),
        open: true
      }
    }));
    CommonUtils.fetchUIDQRcode(QR_CODE_EVENT.HEADER_FIELD);
    CommonUtils.dispatchGaEvent(AUTHHOME_APPLICATION, EVENT_USER_INTERACTION, HEADER_EVENT.HEADER_CLICK_EVENT_ACTION, HEADER_EVENT.CONTINUE_IN_APP_EVENT_LABEL, null);
  }

  /**
   * method to switch l2 page
   * @param {*} selectedId
   */
  handleL2TabClick = (selectedId) => {
    const { AUTHHOME_APPLICATION, EVENT_USER_INTERACTION, HEADER_EVENT } = constants;
    let eventLabel = '';
    if (selectedId === constants.MY_ETEXTBOOK) {
      eventLabel = HEADER_EVENT.MY_ETEXTBOOK_EVENT_LABEL;
      this.setState({ activeHeaderL2Tab: selectedId }, () => {
        this.onSeeAll();
      });
    } else if (selectedId === constants.MORE_AT_PEARSON) {
      eventLabel = HEADER_EVENT.MORE_AT_PEARSON_EVENT_LABEL;
      this.setState({
        authHomeExpandContainer: '',
        actitveNavHeaderTitle: '',
        seeAllView: false,
        isSearched: false,
        showSearch: !CommonUtils.isHEIUser() && !this.isGhostAccount,
        onClickFromHeaderNavMenu: false,
        searchKeyWord: '',
        activeHeaderL2Tab: selectedId
      });
    } else if (selectedId === constants.HEI_LEARN_LANGUAGE) {
      eventLabel = HEADER_EVENT.HEADER_LEARN_LANGUAGE_HEI_USER_LABEL;
      this.setState({ activeHeaderL2Tab: '' });
      window.open(env.HEI_LEARN_LANGUAGE_URL, '_blank');
    }
    CommonUtils.dispatchGaEvent(AUTHHOME_APPLICATION, EVENT_USER_INTERACTION, HEADER_EVENT.HEADER_CLICK_EVENT_ACTION, eventLabel, null);
    this.setState({ drawerOpen: false });
    this.setState({ toggleState: { left: false } });
  }

  /**
   * method to close the l2Moadl
   */
  handleL2ModalClose = () => {
    this.setState(state => ({
      authHomeExpandContainer: '',
      actitveNavHeaderTitle: '',
      seeAllView: false,
      showSearch: !CommonUtils.isHEIUser() && !this.isGhostAccount,
      isSearched: false,
      searchKeyWord: '',
      l2ModalOpen: !state.l2ModalOpen
    }));
  }

  /**
   * method to toggle the drawer
   *
   * @param {*} anchor
   * @param {*} open
   * @returns
   */
  onToggleDrawer = (anchor, open) => {
    this.setState({ drawerOpen: open });
    this.setState(state => ({ toggleState: { ...state.toggleState, [anchor]: open } }));
  };

   /**
   * method to handle the click event
   * 
   */
  handleSearchClick= () => {
    const { AUTHHOME_APPLICATION, HEADER_EVENT, EVENT_USER_INTERACTION } = constants;
    CommonUtils.dispatchGaEvent(AUTHHOME_APPLICATION, EVENT_USER_INTERACTION, HEADER_EVENT.HEADER_CLICK_EVENT_ACTION, HEADER_EVENT.SEARCH_EVENT_LABEL, null);
  }

  renderCourseSection = () => {
    const { language, herobanner, coursecollection } = this.props || {};
    const showSeeAll = CoursePlugin.showSeeAll;

    return (
      <div className="one-reader-content-wrapper">
        <div className="common-wrapper one-reader-add-course-container">
          <Suspense fallback={<CourseSectionLoader />}>
            <CourseSection
              locale={language.currentLanguage}
              primaryCTAText={language.getMessage('course.addTitle')}
              coursesList={coursecollection.getCourseList()}
              onAddCourse={this.coursePlugin.addCourse}
              onCourseClick={this.coursePlugin.handleCourseClick}
              oveRideFullChokeCTA={herobanner.bannerType === constants.HERO_BANNER_TYPES.MARKETING_TEMPLATE}
              showSeeAll={showSeeAll}
              onSeeAllClick={() => { this.setState({ activeHeaderL2Tab: constants.MY_ETEXTBOOK }); this.onSeeAll(); }}
            />
          </Suspense>
        </div>
      </div>
    );
  }

  /**
   * Callback function to close the modal
   */
  handleQRCodeClose = () => {
    if (CommonUtils.controller) {
      CommonUtils.controller.abort();
    }
    this.setState(state => ({
      modalProps: {
        ...state.modalProps,
        open: !state.modalProps.open
      }
    }));
  }
  render() {
    const {
      language,
      userStatus,
      bookshelf,
      user,
      flags,
      ldClient,
      herobanner
    } = this.props;
    const userId = user.id;
    const token = user.token;
    const errorCode = userStatus.error && constants.ERROR_CODE_MARIN;
    Framework.getEventManager().on(constants.BOOKSHELF_DATA_FETCHED, () => {
      this.setState({
        dataFetched: true
      });
    });

    this.searchProps = {
      placeHodler: CommonUtils.isPPlusUser(user) ? language.getMessage('search.searchPlaceHolderPPlus') : language.getMessage('search.searchPlaceHolderEText'), //'Search by ISBN, Title, or Author' : 'Search your list',
      language: language.currentLanguage,
      algoliaConfig: CommonUtils.algoliaConfig(),
      isPPlusUser: CommonUtils.isPPlusUser(user),
      books: getSnapshot(bookshelf.books),
      onhandleFilterBooks: CommonUtils.debounce(this.handleFilterBooks),
      dispatchGaEvent: this.handleSearchEvent,
      showSearch: !CommonUtils.isHEIUser() && !userStatus.isPending && this.state.dataFetched && this.state.showSearch,
      handleSearchClick: ()=> this.handleSearchClick()
    };
    const { AUTHHOME_HEADER_BANNER } = constants.BRAZE_TARGET_AREA;
    const headerContentCard = this.brazePlugin.getContentCardByTargetArea(AUTHHOME_HEADER_BANNER);
    const { enableTitleArchival } = this.state;
    this.changeHeroCTAIfCRBanner(headerContentCard);
    const libraryL2Tabs = this.getLibraryL2Tabs();
    const { authHomeExpandContainer } = this.state;
    const showLibraryL2Tabs = enableTitleArchival && authHomeExpandContainer === 'Library';
    const showMondlyTabs = this.state.activeHeaderL2Tab === constants.MORE_AT_PEARSON;

    const mondlySection = (
      <Fragment>
        {this.hasShown(constants.LD_COMPONENTS_NAME.MONDLY) && (
          <div className="Mondly-container expanded-container-wrapper" data-title="Languages">
            <Suspense fallback={<CommonLoader />}>
              <MondlyLanguageCatlogContainer
                newUI
                getUserCallback={() => ({ userId, token })}
              />
            </Suspense>
          </div>
        )}
      </Fragment>
    );
    return (
      (userStatus.error)
        ? <ErrorCard code={errorCode} />
        : (
          this.isReadyToRender()
            ? <div className="one-reader-app-container">
              <div
                style={{ 'background': '#020917' }}
                className="one-reader-maincontainer"
              >
              {this.nativeAppBannerPlugin.renderMobileBanners()}
              {this.onboardingPlugin.renderOnboard()}
              <Fragment>
                <Suspense fallback={<HeaderLoader />}>
                  <div className={`header ${CommonUtils.isHEIUser() ? 'HEI-header' : ''}`}>
                      <AuthHomeHeader
                        locale={language.currentLanguage}
                        isLoading={false}
                        onLogout={this.handleLogout}
                        onMenuListClick={this.handleMenuClick}
                        settingMenuList={CommonUtils.getAccountMenuListItems(user, this.isGhostAccount)}
                        showSignOutMenu={!this.isGhostAccount}
                        user={this.userDetail}
                        {...this.searchProps}
                        headerItems={this.state.headerMenuNavData}
                        onLogoClick={this.onPearsonLogoClick}
                        enableHeaderNavMenu={this.state.flags.enableGlobalNavigation}
                        actitveNavHeaderTitle={this.state.actitveNavHeaderTitle}
                        title={CommonUtils.getModalProps().title}
                        enableQrCodeButton={this.state.flags.enableQRcode}
                        onQrCodeClick={this.handleQrCodeClick}
                        enableHeaderL2Tabs={this.state.enableHeaderL2Tabs}
                        headerL2Tabs={CommonUtils.getHeaderTabsMenuListItems(getSnapshot(bookshelf.books), this.state.flags, this.state.dataFetched)}
                        activeHeaderL2Tab={this.state.activeHeaderL2Tab}
                        handleHeaderL2TabClick={this.handleL2TabClick}
                        drawerOpen={this.state.drawerOpen}
                        toggleState={this.state.toggleState}
                        onToggleDrawer={this.onToggleDrawer}
                      />
                  </div>
                </Suspense>
                  {
                    (this.state.authHomeExpandContainer !== '' || showMondlyTabs) &&
                    <div className="expandedWrapper one-reader-content-wrapper">
                      {(!this.state.flags.enableGlobalNavigation)
                        && (
                          <Fragment>
                            <BackToHome
                              onBackClick={this.onBackClick}
                              showLibraryTabs={showLibraryL2Tabs}
                              showMondlyTabs={showMondlyTabs}
                              libraryL2Tabs={libraryL2Tabs}
                              activeLibraryL2Tab={this.state.activeLibraryL2Tab}
                              handleLibraryTabClick={this.handleLibraryL2TabClick}
                            />
                          </Fragment>
                        )
                      }
                      {(this.state.l2ModalOpen)
                        && (
                          <L2Modal
                            infoheader="Languages"
                            childComponent={mondlySection}
                            callbacks={{
                              l2ModalClose: this.handleL2ModalClose
                            }}
                          />
                        )}
                      {
                        this.state.authHomeExpandContainer === 'Library' &&
                        <div id="library-authhome" className="library-l2-container common-wrapper" data-title={constants.LOCATION_IN_APP.LIBRARYL2}>
                          <Suspense fallback={<CommonLoader />}>
                            {!enableTitleArchival
                              ? (
                                <LibraryExpand
                                  locale={language.currentLanguage}
                                  books={this.state.isSearched ? this.state.booksData : getSnapshot(bookshelf.books)}
                                  className="libraryExpand"
                                  config={
                                    {
                                      enableSocial: flags.enableSocial,
                                      removeEntitlement: flags.removeEntititlement,
                                      libraryIconTooltipContext: flags.libraryIconTooltipContext || constants.LIBRARY_ICON_TOOLTIP_CONTEXT
                                    }
                                  }
                                  onBookClick={(bookData, type, courseId, bookOrder) => this.handleBookClick(bookData, type, courseId, bookOrder, '', constants.LOCATION_IN_APP.LIBRARY_L2)}
                                  onSortChange={this.handleSortChange}
                                  onInfoTitle={(book, bookOrder) => this.handleInfoTitleClick(book, bookOrder, false)}
                                  onRemoveTitle={(book, bookOrder, isFromInfo) => this.handleRemoveTitlePopup(book, bookOrder, isFromInfo, constants.LOCATION_IN_APP.LIBRARYL2)}
                                  onRemoveTitleHover={() => this.handleRemoveGAEvent(false, true, constants.LOCATION_IN_APP.LIBRARYL2)}
                                  searchKeyWord={this.state.searchKeyWord}
                                  onReactivate={(book) => this.handleReactiveCTAClick(book, constants.LOCATION_IN_APP.LIBRARY_L2)}
                                  onNotificationCTAClick={(event, bookData, isDismissed, launchURL, target, bannerId) => {
                                    this.handleSubscriptionBannerClick(event, bookData, isDismissed, launchURL, target, bannerId, constants.LOCATION_IN_APP.LIBRARY_L2);
                                  }}
                                  onNotificationCTAHover={(bannerId, isDismissed) => this.handleNotificationCTAHover(bannerId, isDismissed, constants.LOCATION_IN_APP.LIBRARYL2)}
                                  onTitleInfoBookCTAClick={(book, type, courseId, bookOrder) => {
                                    CommonUtils.handleInfoBookCTAEvents(book, true, constants.LOCATION_IN_APP.LIBRARY_L2);
                                    this.handleBookClick(book, type, courseId, bookOrder, '',  constants.LOCATION_IN_APP.LIBRARY_L2);
                                  }
                                  }
                                  onTitleInfoBookCTAHover={(book) => { CommonUtils.handleInfoBookCTAEvents(book, false, constants.LOCATION_IN_APP.LIBRARYL2); }}
                                />
                              )
                              : (
                                <LibraryExpandV1
                                  activeTab={this.state.activeLibraryL2Tab}
                                  locale={language.currentLanguage}
                                  books={this.getLibraryL2Books(
                                    this.state.isSearched ? this.state.booksData : getSnapshot(bookshelf.books)
                                  )}
                                  className="library-expand-V1"
                                  config={
                                    {
                                      enableSocial: flags.enableSocial,
                                      removeEntitlement: flags.removeEntititlement,
                                      libraryIconTooltipContext: flags.libraryIconTooltipContext || constants.LIBRARY_ICON_TOOLTIP_CONTEXT,
                                      searchTextChanged: this.state.isSearched
                                    }
                                  }
                                  onArchiveClick={(books, isFromInfo) => this.handleArchiveClick(
                                    books, false, isFromInfo ? constants.LOCATION_IN_APP.INFO : constants.LOCATION_IN_APP.LIBRARYL2
                                  )}
                                  onUnarchiveClick={(books, isFromInfo) => this.handleUnarchiveClick(
                                    books, false, isFromInfo ? constants.LOCATION_IN_APP.INFO : constants.LOCATION_IN_APP.LIBRARYL2
                                  )}
                                  onBookClick={(bookData, type, courseId, bookOrder) => this.handleBookClick(bookData, type, courseId, bookOrder, '', constants.LOCATION_IN_APP.LIBRARY_L2)}
                                  onSortChange={this.handleSortChange}
                                  onInit={this.handleLibraryL2init}
                                  onInfoTitle={(book, bookOrder) => this.handleInfoTitleClick(book, bookOrder, false)}
                                  onRemoveTitle={(book, bookOrder, isFromInfo) => this.handleRemoveTitlePopup(book, bookOrder, isFromInfo, constants.LOCATION_IN_APP.LIBRARYL2)}
                                  onRemoveTitleHover={() => this.handleRemoveGAEvent(false, true, constants.LOCATION_IN_APP.LIBRARYL2)}
                                  searchKeyWord={this.state.searchKeyWord}
                                  onReactivate={(book) => this.handleReactiveCTAClick(book, constants.LOCATION_IN_APP.LIBRARY_L2)}
                                  onNotificationCTAClick={(event, bookData, isDismissed, launchURL, target, bannerId) => {
                                    this.handleSubscriptionBannerClick(event, bookData, isDismissed, launchURL, target, bannerId, constants.LOCATION_IN_APP.LIBRARY_L2);
                                  }}
                                  onNotificationCTAHover={(bannerId, isDismissed) => this.handleNotificationCTAHover(bannerId, isDismissed, constants.LOCATION_IN_APP.LIBRARYL2)}
                                  onTitleInfoBookCTAClick={(book, type, courseId, bookOrder) => {
                                    CommonUtils.handleInfoBookCTAEvents(book, true, constants.LOCATION_IN_APP.LIBRARY_L2);
                                    this.handleBookClick(book, type, courseId, bookOrder, '', constants.LOCATION_IN_APP.LIBRARY_L2);
                                  }
                                  }
                                  onTitleInfoBookCTAHover={(book) => { CommonUtils.handleInfoBookCTAEvents(book, false, constants.LOCATION_IN_APP.LIBRARYL2); }}
                                />
                              )
                            }
                          </Suspense>
                        </div>
                      }
                    </div>
                  }
                  {this.state.authHomeExpandContainer === '' &&
                    <div role="main" class={`one-reader-content-wrapper ${herobanner.bannerType === constants.HERO_BANNER_TYPES.MARKETING_TEMPLATE && 'one-reader-empty-content-wrapper'}` }>
                      {headerContentCard
                        && (
                          <Container
                            enableGutters
                            enableTopAndBottomPadding={false}
                            enableTopPadding
                          >
                            <BrazeContainer
                              data={headerContentCard?.data}
                              callbackForBraze={headerContentCard?.callbackForBraze}
                            />
                          </Container>
                        )
                      }
                      {this.state.headerBannerData
                        && (
                          <Container
                            enableGutters
                            enableTopAndBottomPadding={false}
                            enableTopPadding
                          >
                            <HeaderBanner
                              data={{ ...this.state.headerBannerData }}
                              callbacks={{
                                onCTAClick: this.bannerPlugin.handleHeaderBannerCTAClick
                              }}
                            />
                          </Container>
                        )
                      }
                      {this.renderBasedOnOrder()}
                    </div>
                  }
                  <FooterIllustration />
                  {/* Modal popup dialog area*/}
                  <Suspense fallback={<CommonLoader />}>
                    <RemoveTitleDialog
                      open={this.state.removeTitleOpen}
                      onClose={this.handleRemoveTitleClose}
                      onRemoveTitle={this.handleConfirmRemoveTitle}
                    />
                    {this.state.threatLevel === 1 ? <AccountWarning open={true} onClose={this.handleAccountAbuseClose} /> : <></>}
                    {this.state.threatLevel === 2 ? <AccountLock open={true} onClose={this.handleAccountLockClose} /> : <></>}
                  </Suspense>
                  {this.state.modalProps.open && (
                    <QRCodeModal
                      data={this.state.modalProps}
                      callbacks={{
                        handleQrCodeClick: this.handleQRCodeClose
                      }}
                    />
                  )}

                </Fragment>

                {/* Render Ai Chat */}
                {!this.state.activeHeaderL2Tab && this.authhomeAIStudyToolPlugin.renderAIChatBot()}

              </div>
              <Footer {...CommonUtils.footerMenuList(this.isGhostAccount, this.state.flags.enableQRcode)} />
            </div>
            : <LoadingCard />
        )
    );
  }
}

MLMAuthHomeContainer.propTypes = {
  bookshelf: shapes.modelOf(Bookshelf).isRequired,
  lastlocation: shapes.modelOf(LastLocation).isRequired,
  bookshelfStatus: shapes.state.isRequired,
  lastLocationStatus: shapes.state.isRequired,
  language: shapes.language.isRequired,
  ldClient: PropTypes.object.isRequired,
  match: shapes.match.isRequired,
  user: shapes.user.isRequired,
  userpreferencestatus: shapes.state.isRequired,
  userStatus: shapes.state.isRequired,
  userdetails: shapes.modelOf(userdetailsModel).isRequired,
  productToken: shapes.modelOf(ProductToken).isRequired,
  eligiblityStatus: shapes.state.isRequired,
  flags: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  herobanner: shapes.modelOf(HeroBannerModel).isRequired,
  ldUserHash: shapes.modelOf(LdUserHash).isRequired,
  channelRecommendationsByBook: shapes.modelOf(ChannelRecommendationsByBook).isRequired,
  channelRecommendationsByChannel: shapes.modelOf(ChannelRecommendationsByChannel).isRequired,
  coursecollection: shapes.modelOf(CourseCollection).isRequired
};

export default withLDConsumer()(MLMAuthHomeContainer);
